import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Input,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Grid,
  useToast,
  useDisclosure,
  FormControl,
  FormLabel,

} from "@chakra-ui/react";

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { MdKeyboardBackspace } from "react-icons/md";
import {
  DeleteVendorDetails,
  UpdateVendorDetails,
  getVendorSingleData,
} from "../../../redux/Admin/AppReducer/vendors/action";

import AddSubGroup from "./AddSubGroup";
import { EditSubgroup } from "./EditSubgroup";
import { getData } from "../../../redux/Admin/AppReducer/Products/action";
import ConfirmationModal from "../../../Fucntions/Modals/ConfirmationModal";
import axios from "axios";
import { setToast } from "../../../Fucntions/Toastfunction";
import PasswordResetModal from "./PasswordResetModal";

const VendorDetails = () => {
  const vendor = useSelector((state) => state.VendorReducer.singlevendor);
  // console.log(vendor,"vendor")
  const [vendorPersonalDetail, setVendorPersonalDetail] = useState({
    partner_name: vendor[0]?.partner?.partner_name,
    partner_email:vendor[0]?.partner?.partner_email,
    partner_phone:vendor[0]?.partner?.partner_phone? vendor[0]?.partner?.partner_phone:""
  });

  const [makeFeildsEditable, setMakeFeildsEditable] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => {
    setIsOpen(true);
  };
  const [render,setRender]=useState(false)

  const closeModal = () => {
    setIsOpen(false);
  };
  const { onClose } = useDisclosure();
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const toast = useToast();
  const [savechangesbuttonsubgroup, setSavechangesbuttonsubgroup] =
    useState(false);
  const [subgroupdetails, setSubgroupdetails] = useState({
    cutoff: "",
    name: "",
    time_from: "",
    time_to: "",
    city: [],
  });

  const prod = useSelector((state) => state.ProductReducer.prod);
  const [subgrouparr, setSubgrouparr] = useState([]);
 

  const [associatedproducts, setAssociatedproducts] = useState([]);
  const [associatedproductsbybackend, setassociatedproductsbybackend] =
    useState([]);
  const [aftersavingsubgrouprender, setAfterSavingSubGrouopRender] =
    useState(false);
  function handleDeleteSubGroup(i) {
    const newchar = [...subgrouparr];
    newchar.splice(i, 1);
    setSubgrouparr(newchar);
    setSavechangesbuttonsubgroup(true);
  }

  function handleEditVendorButtonEnabler() {
    setSavechangesbuttonsubgroup(true);
  }

  function SaveVendorsubgroupInfo() {
    //(id)
   

    dispatch(
      UpdateVendorDetails(
        `${process.env.REACT_APP_API_URL}/partner/update`,
        subgroupdetails,
        toast,navigate
      )
    );
    setSavechangesbuttonsubgroup(false);
    setAfterSavingSubGrouopRender(!aftersavingsubgrouprender);
  }


function handleSaveVendorInfoInBackend(){
  const headers = {
    "Authorization":`Bearer ${JSON.parse(sessionStorage.getItem("admintoken"))}`
  };
axios({
  method:"PUT",
  url:`${process.env.REACT_APP_API_URL}/partner/update`,
  data:{...vendorPersonalDetail,partner_id:id},
  headers
}).then((r)=>{
  setToast(toast,"Vendor Information Changed Successfully","","success","top")
  setMakeFeildsEditable(false)
  setRender(!render)

})
.catch((err)=>{
  if(err.response.data.err==="Invalid token"){
    sessionStorage.removeItem('admintoken');
    navigate("/admin/login")
    return
  }
  setToast(toast,`${err.response.data.err}`,"","error","top")
})

}

  function handleDeleteVendor() {
    const data = {
      partner_id: id,
    };
    dispatch(
      DeleteVendorDetails(
        `${process.env.REACT_APP_API_URL}/partner/delete`,
        data,
        toast,navigate
      )
    );
    onClose();
    navigate("/admin/vendor");
  }


  function handleOnchangeVenderDetailInput(e){

const {name,value}=e.target;
setVendorPersonalDetail({
  ...vendorPersonalDetail,
  [name]:value
})
  }


  useEffect(() => {
    if (vendor.length > 0) {
      setSubgrouparr(vendor[0].partner.subgroups || []);
      setassociatedproductsbybackend(
        vendor[0].partner.associated_products || []
      );
      setAssociatedproducts(associatedproductsbybackend);
    }
  }, [associatedproductsbybackend]);

  useEffect(() => {
    dispatch(
      getVendorSingleData(
        `${process.env.REACT_APP_API_URL}/partner/${id}`,"admintoken",navigate
      )
    );
    dispatch(
      getData(
        `${process.env.REACT_APP_API_URL}/product/all`,navigate
      )
    );
  }, [aftersavingsubgrouprender,render]);

  // ************************   FOR UPDATING PASSWORD  ****************************

  const [resetmodal,setResetModal]=useState(false)
  const openResetModal=()=>{
    // console.log(resetmodal,"dsakjkjsa")
    setResetModal(true)
  }
  const closeResetModal=()=>{
    setResetModal(false)
  }

  function handleUpdatePasssword(){

  }

  useEffect(()=>{
setVendorPersonalDetail({
  partner_name: vendor[0]?.partner?.partner_name,
  partner_email:vendor[0]?.partner?.partner_email,
  partner_phone:vendor[0]?.partner?.partner_phone
})
  },[vendor])

  const [load,setLoad]=useState(false)
  useEffect(()=>{
    setTimeout(()=>{
      setLoad(true)
    },500)
  },[])
  return (
    <>
    {
      load?
    <Box  margin="auto">
      
      {vendor &&
        vendor.map((item, i) => (
          <Box key={i}>
            <PasswordResetModal
            partner_id={vendor[0]?.partner?._id}
      isOpen={resetmodal}
      onClose={closeResetModal}/>
            <Flex justifyContent="space-between">
              <Flex mb="20px" alignItems="center" gap="20px">
                <Button
                  onClick={() => navigate(-1)}
                  variant="outline"
                  padding="10px"
                  border="1px solid grey"
                >
                  <MdKeyboardBackspace fontSize="30px" />
                </Button>
                <Heading
                  as="h3"
                  size="xl"
                  fontWeight="medium"
                  mb="10px"
                  textAlign="left"
                >
                  {item?.partner.partner_name}
                </Heading>
              </Flex>
              <Button onClick={openModal} colorScheme="red">
                Delete Vendor
              </Button>
              <ConfirmationModal
                // button="Delete Vendor"
                heading="Delete Vendor"
                action="Are you Sure? You want to Delete this Vendor"
                ConfirmButton="Yes Delete"
                onClickFunction={handleDeleteVendor}
                isOpen={isOpen}
                onClose={closeModal}
              />
            </Flex>
            <Flex
              gap="2rem"
              direction={{ base: "column", md: "row", lg: "row" }}
            >
              {/* ***************************************  1st Box  ************************************ */}
              {/* <Box
                flex="2"
                p="2"
                borderRadius="10px"
                bg="white"
                height="200px"
                boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
                mb="1rem"
              ></Box> */}

              {/* ***************************************  2nd Box  ************************************ */}
              <Box
                p="3"
                flex="1"
                paddingLeft="20px"
                textAlign="left"
                borderRadius="10px"
                bg="white"
                mb="1rem"
                boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
              >
                <Flex
                  justifyContent="space-between"
                  mb="20px"
                  alignItems="center"
                >
                  <Heading as="h3" size="lg" fontWeight="medium">
                    Vendor Details
                  </Heading>
                  <Flex gap="10px">
                  {makeFeildsEditable ? (
                    <Button
                      colorScheme="red"
                      onClick={() => setMakeFeildsEditable(false)}
                    >
                      cancel
                    </Button>
                  ) : (
                    <Button
                      colorScheme="blue"
                      onClick={() => setMakeFeildsEditable(true)}
                    >
                      Edit
                    </Button>
                  )}
                  <Button variant="outline" colorScheme="blue"
                  onClick={()=>openResetModal()}
                  >Reset Password</Button>
                  </Flex>
                </Flex>
                {makeFeildsEditable ? (
                  <Box>
                    <FormControl mb="15px">
                      <FormLabel>Enter Email address</FormLabel>
                      <Input type="email" placeholder="Enter Email"
                      name="partner_email"
                      onChange={handleOnchangeVenderDetailInput}
                      value={vendorPersonalDetail.partner_email}/>
                    </FormControl>
                    <FormControl mb="15px">
                      <FormLabel>Enter Name</FormLabel>
                      <Input placeholder="Enter Name"
                      name="partner_name"
                        onChange={handleOnchangeVenderDetailInput}
                      value={vendorPersonalDetail.partner_name}/>
                    </FormControl>
                    <FormControl mb="15px">
                      <FormLabel>Enter Phone No</FormLabel>
                      <Input placeholder="Enter Phone"
                      name="partner_phone" type="number" maxLength={10}
                        onChange={handleOnchangeVenderDetailInput}
                      value={vendorPersonalDetail?.partner_phone} max={10}/>
                    </FormControl>

                    <Button
                      onClick={handleSaveVendorInfoInBackend}
                      isDisabled={vendorPersonalDetail.partner_name===""  || vendorPersonalDetail.partner_email===""}
                      colorScheme="green"
                    >
                      Save
                    </Button>
                  </Box>
                ) : (
                  <Box>
                    <Text fontSize="xl">
                      {" "}
                      VendorId : {item.partner._id}
                    </Text>
                    <Text fontSize="xl">
                      {" "}
                      Email : {item.partner.partner_email}
                    </Text>
                    <Text fontSize="xl">
                      {" "}
                      Name : {item.partner.partner_name}
                    </Text>
                    <Text fontSize="xl">
                      {" "}
                      Phone : {item?.partner.partner_phone}
                    </Text>
                  </Box>
                )}
              </Box>
            </Flex>
          </Box>
        ))}
      <Box
        boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
        bg="white"
        p={{ base: "0", md: "2", lg: "2" }}
        borderRadius="10px"
      >
        <Tabs isFitted variant="enclosed" mt="3rem">
          <TabList mb="1em">
            <Tab _selected={{ bg: "gray.100" }}>
              <Heading as="h6" size="md" fontWeight="semibold">
                PRODUCTS
              </Heading>
            </Tab>
            {/* <Tab _selected={{ bg: "gray.100" }}>
              <Heading as="h6" size="md" fontWeight="semibold">
                SUB GROUPS
              </Heading>
            </Tab> */}
          </TabList>
          <TabPanels>
            {/* ****************************************  PRODUCT  TABS STARTED  *********************************************** */}
            <TabPanel>
              <Flex
                gap="2rem"
                alignItems="flex-end"
                mb="1.5rem"
                fontSize={{ base: "12px", md: "16px", lg: "16px" }}
              >
                {/* {showVendorProductEdit || associatedproducts.length === 0  ? <VendorRelatedProduct
                name={"Add Products"}
                color="blue"
                heading={"Search Product"}
                onClick={handleSaveProducts}
                associatedproductsbybackend={associatedproductsbybackend}
                associatedproducts={associatedproducts}
                setAssociatedproducts={setAssociatedproducts}
                setshowVendorProductEdit={setshowVendorProductEdit}
              />:null}   */}
                {/* {showVendorProductEdit ? (
                  <Button
                    colorScheme="green"
                    onClick={handleSaveChangesInProducts}
                    fontSize={{ base: "12px", md: "16px", lg: "16px" }}
                  >
                    Save Changes
                  </Button>
                ) : (
                  <Button
                    colorScheme="blue"
                    onClick={handleEditInProducts}
                    display={associatedproducts.length > 0 ? "block" : "none"}
                  >
                    Edit
                  </Button>
                )} */}
              </Flex>

              <Box>
                {vendor[0]?.partner?.associated_products.map((item, i) => (
                  <Flex
                    p={4}
                    key={i}
                    gap={{ base: "10px", md: "20px", lg: "60px" }}
                    mb={"10px"}
                    bg="gray.100"
                    onClick={() => navigate(`/admin/products/${item.id}`)}
                    _hover={{ cursor: "pointer" }}
                  >
                    <Image src={item.images[0]} width="40px" heigth="50px" />
                    <Text>id : {item.id}</Text>
                    <Text>Price : {item.showPrice}</Text>
                    <Text alignItems="center">title : {item.title}</Text>
                  </Flex>
                ))}
              </Box>
            </TabPanel>

            {/* ****************************************  SUBGROUP  TABS STARTED  *********************************************** */}
            <TabPanel>
              <Flex justifyContent="space-between" mb="20px">
                {/* ******************  BUTTONS ***************** */}
                <Flex gap="10px">
                  <AddSubGroup
                    title={"Add"}
                    color={"blue"}
                    savechangesbuttonsubgroup={savechangesbuttonsubgroup}
                    setSavechangesbuttonsubgroup={setSavechangesbuttonsubgroup}
                    subgroupdetails={subgroupdetails}
                    setSubgroupdetails={setSubgroupdetails}
                    subgrouparr={subgrouparr}
                    setSubgrouparr={setSubgrouparr}
                  />
                  {savechangesbuttonsubgroup ? (
                    <Button
                      display={
                        subgrouparr.length > 0
                          ? "block"
                          : savechangesbuttonsubgroup
                          ? "block"
                          : "none"
                      }
                      colorScheme="green"
                      onClick={SaveVendorsubgroupInfo}
                    >
                      SAVE
                    </Button>
                  ) : (
                    // ***********************  TO ENABLE THE EDIT FUNCTIONSLITY ***************
                    <Button
                      // display={subgrouparr.length > 0 ? "block" : "none"}
                      colorScheme="blue"
                      onClick={handleEditVendorButtonEnabler}
                    >
                      EDIT
                    </Button>
                  )}
                </Flex>
              </Flex>
              {/* ******************  SUBGROUP STARTING ***************** */}
              {subgrouparr.length === 0 ? (
                <Text fontSize="2xl" color="gray">
                  NO SUB GROUPS YET
                </Text>
              ) : (
                <Box>
                  {/* ***********************  SUB GROUP BOX ******************************** */}
                  {subgrouparr.map((item, i) => (
                    <Flex
                      key={i}
                      p="3"
                      bg="gray.100"
                      mb="10px"
                      flex="2"
                      borderRadius="5px"
                      justifyContent="space-between"
                    >
                      {/* ***********************  SUBGROUP DETAILS  ******************************** */}
                      <Flex flexDirection="column" textAlign="left">
                        <Text>Name : {item.name}</Text>
                        <Text>Cutt-Off Time : {item.cutoff} hr</Text>
                        <Text>
                          Delivery Time : {item.time_from} - {item.time_to}
                        </Text>
                        <Flex gap="20px">
                          <Text>city : </Text>
                          <Grid templateColumns="repeat(10, minmax(0px, auto))">
                            {item.city &&
                              item.city.map((item, i) => (
                                <Box key={i}>
                                  {i < subgrouparr[0].city.length - 1 ? (
                                    <Text>{item}, </Text>
                                  ) : (
                                    <Text>{item}</Text>
                                  )}
                                </Box>
                              ))}
                          </Grid>
                        </Flex>
                      </Flex>
                      {/* ***********************  BUTTONS FOR EDIT AND DELETE  ******************************** */}
                      {savechangesbuttonsubgroup ? (
                        <Flex
                          flexDirection={{
                            base: "column",
                            md: "row",
                            lg: "row",
                          }}
                          gap="3rem"
                        >
                          <EditSubgroup
                            index={i}
                            color={"blue"}
                            title={"EDIT"}
                            subgroupdetails={subgrouparr[i]}
                            setSubgroupdetails={setSubgroupdetails}
                            subgrouparr={subgrouparr}
                            setSubgrouparr={setSubgrouparr}
                          />
                          <Text
                            height="fit-content"
                            onClick={() => handleDeleteSubGroup(i)}
                            _hover={{
                              textDecoration: "underline",
                              color: "blue",
                              cursor: "pointer",
                            }}
                          >
                            DELETE
                          </Text>
                        </Flex>
                      ) : null}
                    </Flex>
                  ))}
                </Box>
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Box> :<Text color="gray.800" fontSize="40px" 
      fontWeight="semibold" mt="80px" pb="80px">Loading ...</Text>
    }
    </>
  );
};

export default VendorDetails;
