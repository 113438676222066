// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../src//font/NexaRegular.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../src/font/NexaBold.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../src/font/NexaHeavy.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../src/font/Inter-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("../src/font/Arslan.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("../src/font/Inter-Italic.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  text-align: center;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-display: swap;
  font-family: 'NexaText-Trial-Regular';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype')
}
@font-face {
  font-display: swap;
  font-family: 'NexaText-Trial-Bold';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('truetype')
}

@font-face {
  font-display: swap;
  font-family: 'NexaText-Trial-Heavy';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('truetype')
}

@font-face {
  font-display: swap;
  font-family: 'Inter-Medium';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('truetype')
}

@font-face {
  font-display: swap;
  font-family: 'Arslan';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format('truetype')
}

@font-face {
  font-display: swap;
  font-family: 'Inter-Italic';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format('truetype')
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;AACA;EACE,kBAAkB;EAClB,qCAAqC;EACrC;AACF;AACA;EACE,kBAAkB;EAClB,kCAAkC;EAClC;AACF;;AAEA;EACE,kBAAkB;EAClB,mCAAmC;EACnC;AACF;;AAEA;EACE,kBAAkB;EAClB,2BAA2B;EAC3B;AACF;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;EACrB;AACF;;AAEA;EACE,kBAAkB;EAClB,2BAA2B;EAC3B;AACF;;AAEA;EACE;aACW;AACb","sourcesContent":["body {\n  text-align: center;\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n@font-face {\n  font-display: swap;\n  font-family: 'NexaText-Trial-Regular';\n  src: url('../src//font/NexaRegular.otf') format('truetype')\n}\n@font-face {\n  font-display: swap;\n  font-family: 'NexaText-Trial-Bold';\n  src: url('../src/font/NexaBold.otf') format('truetype')\n}\n\n@font-face {\n  font-display: swap;\n  font-family: 'NexaText-Trial-Heavy';\n  src: url('../src/font/NexaHeavy.otf') format('truetype')\n}\n\n@font-face {\n  font-display: swap;\n  font-family: 'Inter-Medium';\n  src: url('../src/font/Inter-Medium.ttf') format('truetype')\n}\n\n@font-face {\n  font-display: swap;\n  font-family: 'Arslan';\n  src: url('../src/font/Arslan.ttf') format('truetype')\n}\n\n@font-face {\n  font-display: swap;\n  font-family: 'Inter-Italic';\n  src: url('../src/font/Inter-Italic.ttf') format('truetype')\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
