import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../Pages/Home";
import Authentication from "../../Admin/Authentication/Authentication";
import Products from "../Pages/Product/Products";
import ProductDetails from "../Pages/Product/ProductDetails";
import Vendor from "../Pages/Vendor/Vendor";
import Login from "../Pages/Login";
import VendorDetails from "../Pages/Vendor/VendorDetails";
import CreateVendor from "../Pages/Vendor/CreateVendor";
import SubOrderPage from "../Pages/SubOrders/SubOrderPage";
import AllSuborder from "../Pages/orders/AllSuborder";
import AddSubOrder from "../Pages/orders/AddSubOrder";
import AllOrders from "../Pages/orders/AllOrders";
import AllOrderDetail from "../Pages/orders/AllOrderDetail";
import Citys from "../Pages/Vendor/City(CityGroup)/Citys";
import ShippingMethodList from "../Pages/Shipping/ShippingMethodList";
import VariantDetails from "../Pages/variants/VariantDetails";
import AdminUsers from "../Pages/Users/AdminUsers";
import AdminRoles from "../Pages/Roles/AdminRoles";

import EditMainOrder from "../Pages/orders/EditMainOrder/EditMainOrder";
import EditSubOrder from "../Pages/orders/EditSubOrder/EditSubOrder";
import OrderDetailpage from "../Pages/orders/OrderDetailpage";
import SubOrderDetail from "../Pages/orders/SubOrderDetail";
import OrderEditingPage from "../Pages/orders/OrderEditingPage";
import { Text } from "@chakra-ui/react";
import DateTimeBlocker from "../Pages/DateTimeBlock/DateTimeBlocker";
import OrderDetail from "../Pages/SubOrders/OrderDetail";
import MainOrderPage from "../Pages/SubOrders/MainOrderPage";
import SourceTag from "../Pages/SourceTag";
import Settings from "../Pages/Settings/Settings";

const Allroutes = () => {
  return (
    <div>
      <Routes>
        <Route path="/admin/login" element={<Login />} />
        {/* <Route path="/" element={<Error />} /> */}
        <Route
          path="/admin"
          element={
            <Authentication>
              <Home />
            </Authentication>
          }
        />
        <Route
          path="/admin/roles"
          element={
            <Authentication>
              <AdminRoles />
            </Authentication>
          }
        />
        <Route
          path="/admin/products"
          element={
            <Authentication>
              <Products />
            </Authentication>
          }
        />

        <Route
          path="admin/users"
          element={
            <Authentication>
              <AdminUsers />
            </Authentication>
          }
        />
        <Route
          path="admin/products/:id"
          element={
            <Authentication>
              <ProductDetails />
            </Authentication>
          }
        />
        <Route
          path="admin/product/:pid/variant/:vid"
          element={
            <Authentication>
              <VariantDetails />
            </Authentication>
          }
        />
        <Route
          path="admin/vendor"
          element={
            <Authentication>
              <Vendor />
            </Authentication>
          }
        />
        <Route
          path="admin/vendor/:id"
          element={
            <Authentication>
              <VendorDetails />
            </Authentication>
          }
        />

        <Route
          path="admin/createvendor"
          element={
            <Authentication>
              <CreateVendor />
            </Authentication>
          }
        />
        <Route
          path="admin/orderdetail/:id"
          element={
            <Authentication>
              <MainOrderPage />
            </Authentication>
          }
        />

        <Route
          path="admin/order/suborder/:all"
          element={
            <Authentication>
              <AllSuborder />
            </Authentication>
          }
        />
        <Route
          path="admin/order/suborder/:filter/:id"
          element={
            <Authentication>
              <SubOrderDetail />
            </Authentication>
          }
        />
        <Route
          path="admin/order/suborder/editorder/:id"
          element={
            <Authentication>
              <EditSubOrder />
            </Authentication>
          }
        />
        <Route
          path="admin/order/addsuborder"
          element={
            <Authentication>
              <AddSubOrder />
            </Authentication>
          }
        />
        <Route
          path="admin/order/allorder"
          element={
            <Authentication>
              <AllOrders />
            </Authentication>
          }
        />
        {/* <Route
          path="admin/order/all"
          element={
            <Authentication>
              <AllOrders/>
            </Authentication>
          }
        />   */}
        <Route
          path="admin/order/:id"
          element={
            <Authentication>
              <OrderDetailpage />
            </Authentication>
          }
        />

        <Route
          path="admin/order/editorder/:id"
          element={
            <Authentication>
              <OrderEditingPage />
            </Authentication>
          }
        />

        <Route
          path="admin/cities"
          element={
            <Authentication>
              <Citys />
            </Authentication>
          }
        />

        <Route
          path="admin/shippingmethods"
          element={
            <Authentication>
              <ShippingMethodList />
            </Authentication>
          }
        />

        <Route
          path="admin/sourcetags"
          element={
            <Authentication>
              <SourceTag />
            </Authentication>
          }
        />
        <Route
          path="admin/settings"
          element={
            <Authentication>
              <Settings />
            </Authentication>
          }
        />

        <Route
          path="admin/datetimeblocker"
          element={
            <Authentication>
              <DateTimeBlocker />
            </Authentication>
          }
        />

        <Route
          path="/*"
          element={
            <Text
              color="red.400"
              fontSize="40px"
              fontWeight="semibold"
              mt="80px"
              pb="80px"
            >
              Page Does Not Found
            </Text>
          }
        />
      </Routes>
    </div>
  );
};

export default Allroutes;
