import React, { Fragment, useEffect, useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Box,
  Flex,
  Heading,
  Button,
  Text,
  Input,
  Modal,
  FormControl,
  FormLabel,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Td,
  TableContainer,
  useDisclosure,
  Select,
  Checkbox,
  InputGroup,
  InputRightElement,
  useToast,
  IconButton,
  Badge,
  Grid,
  Spinner,
} from "@chakra-ui/react";
import { CiEdit } from "react-icons/ci";
import { AiOutlineDelete } from "react-icons/ai";
import axios from "axios";
import { setToast } from "../../../Fucntions/Toastfunction";
import { AddIcon, CloseIcon } from "@chakra-ui/icons";
import ConfirmationModal from "../../../Fucntions/Modals/ConfirmationModal";
import { useNavigate } from "react-router-dom";

const AdminUsers = () => {
  
  const [userid, setUserid] = useState(null);
  const [confirmisOpen, setConfirmisOpen] = useState(false);
  const openConfirmModal = (id) => {
    setConfirmisOpen(true);
    setUserid(id);
  };
  const closeConfirmModal = () => setConfirmisOpen(false);
  const [showupdatepassword, setShowUpdatepassword] = useState(false);
  const [updatebutton, setUpdateButton] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const navigate=useNavigate()
  const [pass, setPass] = useState(false);
  const handlepassClick = () => setPass(!pass);
  const [confirmpass, setConfirmpass] = useState(false);
  const handleconfirmpassClick = () => setConfirmpass(!confirmpass);
  const [roles, setRoles] = useState([]);
  const [user, setUser] = useState(null);
  const [render, setRender] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [query, setQuery] = useState("");
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [showSelectedRoles, setShowSelectedRoles] = useState([]);
  const [userDetail, setUserDetail] = useState({
    name: "",
    username: "",
    password: "",
    confirm_password: "",
  });
// console.log(user,"user")
  const addRolesHandler = (e) => {
    e.stopPropagation();
    setToggle(true);
  };

  function handlesaveSelectedRoles() {
    setToggle(false);
    setQuery("");
    setShowSelectedRoles(selectedRoles);
  }

  
  const [updateloader,setUpdateLoader]=useState(false)
  function handleUpdateUserHitApi() {

    if (showupdatepassword) {
      if (userDetail.password === "" || userDetail.confirm_password === "") {
        setToast(toast, "Please Enter Password", "", "error");
        return;
      }
      if (userDetail.password !== userDetail.confirm_password) {
        setToast(toast, "Password Does not Match", "", "error");
        return;
      }
    } else {
      delete userDetail.password;
      delete userDetail.confirm_password;
    }
  
    // if (Object.values(userDetail).includes("")) {
    //     setToast(toast, "Please fill All the details", "", "error");
    //     return;
    // }

    if (showupdatepassword && userDetail.confirm_password === undefined) {
      setToast(toast, "Please Enter Password", "", "error");
      return;
    }
    delete userDetail._id;
    setUpdateLoader(true)
    const headers = {

      Authorization: `Bearer ${JSON.parse(
        sessionStorage.getItem("admintoken")
      )}`,
    };
   
    axios({
      method: "PUT",
      url: `${process.env.REACT_APP_API_URL}/user/${userid}`,
      data: { ...userDetail, userGroup: selectedRoles },
      headers,
    })
      .then((r) => {
        setToast(toast, `User Updated Successfully `, "", "success");
        onClose();
        setRender(!render);
        setUpdateLoader(false)
      })
      .catch((err) => {
        if(err.response.data.err==="Invalid token"){
          sessionStorage.removeItem('admintoken');
          navigate("/admin/login")
          return
        }
        setUpdateLoader(false)
        setToast(toast, `${err.response.data.err}`, "", "error");
      });
  }

  function handleEditUserButtonClicked(user) {
   
    setUserid(user._id)
    const merged = Object.assign(userDetail, user);
    
    setUserDetail(merged);
    setSelectedRoles(merged.userGroup);
    setShowSelectedRoles(merged.userGroup);
    setUpdateButton(true);
    onOpen();
  }

  function handleInputChange(e) {
    const { name, value } = e.target;


    // Check if the first character is a space
    if (value.charAt(0) === ' ') {
      // Remove the first character (space)
      e.target.value = value.slice(1);
      return
    }
    if (name === "confirm_password") {
      if (e.nativeEvent?.inputType === "deleteContentBackward") {
        setUserDetail({
          ...userDetail,
          [name]: value,
        });
        return;
      }
      if (userDetail.password === "") {
        setToast(toast, "Enter your password First", "", "error");
        return;
      }
    }

    setUserDetail({
      ...userDetail,
      [name]: value,
    });
  }

  const [createloader,setCreateLoader]=useState(false)
  const handleCreateUserHitApi = () => {
    if(createloader){
      return
    }
    if (userDetail.password !== userDetail.confirm_password) {
      setToast(toast, "Password Does not Match", "", "error");
      return;
    }
    // if (Object.values(userDetail).includes("")) {
    //     setToast(toast, "Please fill All the details", "", "error");
    //     return;
    // }

    setCreateLoader(true)
    const headers = {
  
      Authorization: `Bearer ${JSON.parse(
        sessionStorage.getItem("admintoken")
      )}`,
    };
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/user`,
      data: { ...userDetail, userGroup: selectedRoles },
      headers,
    })
      .then((r) => {
        setCreateLoader(false)
        setToast(toast, `User Successfully Created`, "", "success");
        onClose();
        setRender(!render);
      })
      .catch((err) => {
        if(err.response.data.err==="Invalid token"){
          sessionStorage.removeItem('admintoken');
          navigate("/admin/login")
          return
        }
        setCreateLoader(false)
        setToast(toast, `${err.response.data.err}`, "", "error");
      });
  };

  function getUserRolesfromBackend() {
    const headers = {
   
      Authorization: `Bearer ${JSON.parse(
        sessionStorage.getItem("admintoken")
      )}`,
    };
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/usergroups`,
      headers,
    })
      .then((r) => {
   
        setRoles(r.data);
      })
      .catch((err) => {
        if(err.response.data.err==="Invalid token"){
          sessionStorage.removeItem('admintoken');
          navigate("/admin/login")
          return
        }
        setToast(toast, `${err.response.data.err}`, "", "error");
      });
  }
 let [userLoading,setUserLoading]=useState(false)
  function getAllUsersFromBackend() {
    if(userLoading){
      return
    }
    setUserLoading(true)
    const headers = {
  
      Authorization: `Bearer ${JSON.parse(
        sessionStorage.getItem("admintoken")
      )}`,
    };
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/user/all`,
      headers,
    })
      .then((r) => {
        setUser(r.data);
        setUserLoading(false)
      })
      .catch((err) => {
        if(err.response.data.err==="Invalid token"){
          sessionStorage.removeItem('admintoken');
          navigate("/admin/login")
          return
        }
        // console.log(err);
        setToast(toast,"Something Went Wrong","","error")
        setUserLoading(false)
      });
  }
const [deleteloading,setDeleteLoading]=useState(false)
  function handleDeleteUserButtonClickedApiHit() {
    if(deleteloading){
      return
    }
    setDeleteLoading(true)
    const headers = {
      Authorization: `Bearer ${JSON.parse(
        sessionStorage.getItem("admintoken")
      )}`,
    };
    axios({
      method: "DELETE",
      url: `${process.env.REACT_APP_API_URL}/user/${userid}`,
      headers,
    })
      .then((r) => {
        setDeleteLoading(false)
        setToast(toast, `${r.data.success}`, "", "success");
        setRender(!render);
        closeConfirmModal();
      })
      .catch((err) => {
        if(err.response.data.err==="Invalid token"){
          sessionStorage.removeItem('admintoken');
          navigate("/admin/login")
          return
        }
        setDeleteLoading(false)
        setToast(toast, `${err.response.data.err}`, "", "error");
      });
  }

  useEffect(() => {
    getUserRolesfromBackend();
    getAllUsersFromBackend();
  }, [render]);

  return (
    <>
    {
      userLoading?   
      <Text color="gray.800" fontSize="40px" 
        fontWeight="semibold" mt="80px" pb="80px">Loading ...</Text>:
      <Box width="100%">
      <Flex justifyContent="space-between">
        <Heading as="h4" size="lg" fontWeight="medium">
          Users
        </Heading>
        <Flex gap="10px">
          <Button
            colorScheme="green"
            onClick={() => {
              setUpdateButton(false);
              onOpen();
              setUserDetail({
                name: "",
                username: "",
                password: "",
                confirm_password: "",
                phone: "",
              });
              setSelectedRoles([]);
              setShowSelectedRoles([]);
            }}
            leftIcon={<AddIcon/>}
          >
            Add Users
          </Button>
        </Flex>
      </Flex>
      <br />

      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        p={3}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {updatebutton ? "Update User" : "Create New User"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody p={6} textAlign="left">
            <FormControl mb="10px">
              <FormLabel>User Name</FormLabel>
              <Input
                value={userDetail.username}
                onChange={handleInputChange}
                name="username"
              />
            </FormControl>
            <FormControl mb="10px">
              <FormLabel>Name</FormLabel>
              <Input
                onChange={handleInputChange}
                name="name"
                value={userDetail.name}
              />
            </FormControl>
            {/* <FormControl mb="10px">
              <FormLabel>Phone Number</FormLabel>
              <Input
                type="number"
                onChange={handleInputChange}
                name="phone"
                value={userDetail.phone}
              />
            </FormControl> */}
            {/* ************************** FOR UPDATING ************************************** */}
            {updatebutton ? (
              <Box>
                <Checkbox
                  size="lg"
                  colorScheme="orange"
                  fontWeight="semibold"
                  mb="20px"
                  onChange={(e) => {
                    if (e.target.checked) {
                      setShowUpdatepassword(true);
                    } else {
                      setShowUpdatepassword(false);
                    }
                  }}
                >
                  Update Password
                </Checkbox>
                {showupdatepassword ? (
                  <>
                    <FormControl mb="10px">
                      <FormLabel>Update Password</FormLabel>
                      <InputGroup size="md">
                        <Input
                          name="password"
                          onChange={handleInputChange}
                          pr="4.5rem"
                          type={pass ? "text" : "password"}
                          placeholder="Enter password"
                        />
                        <InputRightElement width="4.5rem">
                          <Button
                            h="1.75rem"
                            size="sm"
                            onClick={handlepassClick}
                          >
                            {pass ? "Hide" : "Show"}
                          </Button>
                        </InputRightElement>
                      </InputGroup>
                    </FormControl>
                    <FormControl mb="10px">
                      <FormLabel>Confirm Password</FormLabel>
                      <InputGroup size="md">
                        <Input
                          name="confirm_password"
                          onChange={handleInputChange}
                          pr="4.5rem"
                          type={confirmpass ? "text" : "password"}
                          placeholder="Confirm password"
                        />
                        <InputRightElement width="4.5rem">
                          <Button
                            h="1.75rem"
                            size="sm"
                            onClick={handleconfirmpassClick}
                          >
                            {confirmpass ? "Hide" : "Show"}
                          </Button>
                        </InputRightElement>
                      </InputGroup>
                    </FormControl>
                  </>
                ) : null}
              </Box>
            ) : (
              <Box>
                <FormControl mb="10px">
                  <FormLabel>Enter Password</FormLabel>
                  <InputGroup size="md">
                    <Input
                      name="password"
                      onChange={handleInputChange}
                      pr="4.5rem"
                      type={pass ? "text" : "password"}
                      placeholder="Enter password"
                    />
                    <InputRightElement width="4.5rem">
                      <Button h="1.75rem" size="sm" onClick={handlepassClick}>
                        {pass ? "Hide" : "Show"}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
                <FormControl mb="10px">
                  <FormLabel>Confirm Password</FormLabel>
                  <InputGroup size="md">
                    <Input
                      name="confirm_password"
                      onChange={handleInputChange}
                      pr="4.5rem"
                      type={confirmpass ? "text" : "password"}
                      placeholder="Confirm password"
                    />
                    <InputRightElement width="4.5rem">
                      <Button
                        h="1.75rem"
                        size="sm"
                        onClick={handleconfirmpassClick}
                      >
                        {confirmpass ? "Hide" : "Show"}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
              </Box>
            )}
            <FormControl mb="10px">
              <FormLabel>Roles</FormLabel>
              <Input
                placeholder={"Select Roles"}
                onClick={(e) => {
                  addRolesHandler(e);
                }}
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
              {toggle && (
                <Box
                  textAlign="left"
                  position={"absolute"}
                  zIndex={"1"}
                  bgColor={"white"}
                  width={"100%"}
                  border="1px"
                  borderColor="gray.300"
                  rounded={"md"}
                >
                  <Flex width={"100%"} justifyContent={"flex-end"} py={2}>
                    <Button
                      size={"xs"}
                      colorScheme="green"
                      mx={2}
                      onClick={handlesaveSelectedRoles}
                    >
                      Save Changes
                    </Button>
                  </Flex>
                  <Flex
                    maxHeight={`${window.innerHeight - 400}px`}
                    overflowY={"scroll"}
                    overflowX={"hidden"}
                  >
                    <FormControl py={2} px={4} pt={0}>
                      {roles
                        .filter((e) =>
                          e.name
                            .toLowerCase()
                            .includes(query.toLocaleLowerCase())
                        )
                        .map((item, i) => {
                          return (
                            <Fragment key={i}>
                              <Checkbox
                                isChecked={selectedRoles.some(
                                  (val) => val === item._id
                                )}
                                onChange={(e) => {
                                  if (e.target.checked === true) {
                                    let arr = [...selectedRoles];
                                    arr.push(item._id);
                                    setSelectedRoles(arr);
                                  } else if (e.target.checked === false) {
                                    let arr = [...selectedRoles];
                                    let newarr = arr.filter(
                                      (val) => val !== item._id
                                    );
                                    setSelectedRoles(newarr);
                                  }
                                }}
                              >
                                {item.name}
                              </Checkbox>
                              <br />
                            </Fragment>
                          );
                        })}
                    </FormControl>
                  </Flex>
                </Box>
              )}
            </FormControl>
            <Grid gap="15px" templateColumns="repeat(3,1fr)">
              {showSelectedRoles.map((item, i) => (
                <Badge
                  key={i}
                  p={2}
                  width="fit-content"
                  color="GrayText"
                  borderRadius="5px"
                >
                  {roles.find((role) => item === role._id)?.name}
                  {/* <CloseIcon
            fontSize="25px"
            padding="5px"
            borderRadius="50%"
            onClick={()=>{

            }}
            _hover={{
              cursor: "pointer",
              transition: "0.2s",
              transform: "scale(1.03)",
              boxShadow: "0 0 2px black",
            }}
          /> */}
                </Badge>
              ))}
            </Grid>
          </ModalBody>
          <ModalFooter gap="10px">
            {updatebutton ? (
              <Button colorScheme="green" onClick={handleUpdateUserHitApi}>
               {updateloader?<Spinner/>:"Update"}
              </Button>
            ) : (
              <Button
                colorScheme="blue"
                mr={3}
                onClick={handleCreateUserHitApi}
              >
                {createloader?<Spinner/>:"Save"}
              </Button>
            )}
            <Button onClick={onClose} variant="outline" border="2px solid teal">
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <ConfirmationModal
        heading="Delete User"
        action="Are you Sure? You want to Delete this User"
        ConfirmButton="Yes Delete"
        onClickFunction={handleDeleteUserButtonClickedApiHit}
        isOpen={confirmisOpen}
        onClose={closeConfirmModal}
        loader={true}
        loading={deleteloading}
      />

      <div
        style={{
          borderRadius: "7px",
          boxShadow:
            "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
          backgroundColor: "white",
        }}
        height="30px"
        width="30px"
      >
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>
                  <Text>S.No</Text>
                </Th>
                <Th>
                  <Text fontSize="sm">Name</Text>
                </Th>
                <Th>
                  <Text fontSize="sm">UserName</Text>
                </Th>
                <Th>
                  <Text fontSize="sm">Role</Text>
                </Th>
                <Th>
                  <Text fontSize="sm" paddingLeft="20px">
                    Action
                  </Text>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {user?.map((item, i) => (
                <Tr key={i}>
                  <Td>{i + 1}</Td>
                  <Td>{item.name}</Td>
                  <Td>{item.username}</Td>
                  <Td>
                    {item.userGroup.map((id, i) => (
                      <Box key={i}>
                        {item.userGroup.length - 1 === i ? (
                          <Text>
                            {roles.find((role) => id === role._id)?.name}{" "}
                          </Text>
                        ) : (
                          <Text>
                            {roles.find((role) => id === role._id)?.name} ,
                          </Text>
                        )}
                      </Box>
                    ))}
                  </Td>
                  <Td>
                    
                   {process.env.REACT_APP_SUPER_ADMIN_USER_ID!==item._id && <Flex gap="30px">
                      <IconButton
                      color="blue.500"
                        onClick={() => handleEditUserButtonClicked(item)}
                        icon={<CiEdit fontSize="30px" />}
                        variant="outline"
                      />
                      <IconButton
                      color="red.500"
                        onClick={() => openConfirmModal(item._id)}
                        icon={<AiOutlineDelete fontSize="30px" />}
                        variant="outline"
                      />
                    </Flex>}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </div>
    </Box>
    }
    </>
    
  );
};

export default AdminUsers;
