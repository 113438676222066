// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-box {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 5px;
  
  }
  
  .search-container {
    position: relative;
    width: 100%;
  }
  
  input[type="text"] {
    padding-left: 30px; /* set padding to accommodate search icon */
    border: none;
    outline: none;
    width: 100%;
  }
  
  .search-icon {
    position: absolute;
    top: 12px;
    left: 8px;
    color: #ccc;
  }`, "",{"version":3,"sources":["webpack://./src/Fucntions/search/search.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,kBAAkB;;EAEpB;;EAEA;IACE,kBAAkB;IAClB,WAAW;EACb;;EAEA;IACE,kBAAkB,EAAE,2CAA2C;IAC/D,YAAY;IACZ,aAAa;IACb,WAAW;EACb;;EAEA;IACE,kBAAkB;IAClB,SAAS;IACT,SAAS;IACT,WAAW;EACb","sourcesContent":[".search-box {\n    display: flex;\n    align-items: center;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n  \n  }\n  \n  .search-container {\n    position: relative;\n    width: 100%;\n  }\n  \n  input[type=\"text\"] {\n    padding-left: 30px; /* set padding to accommodate search icon */\n    border: none;\n    outline: none;\n    width: 100%;\n  }\n  \n  .search-icon {\n    position: absolute;\n    top: 12px;\n    left: 8px;\n    color: #ccc;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
