import React, { useState } from "react";
import OrderStatusComp from "./OrderStatusComp";

const RejectedOrders = () => {
  let [policy, setPolicy] = useState([]);
  let [buttons_name, setButtonname] = useState([]);
  let [button_modal, setButtonModal] = useState([]);
  return (
    <OrderStatusComp
      status={"rejected"}
      order_status={"rejected_orders"}
      heading={"Rejected Orders"}
      selection={true}
      challanshow={false}
      // button_action={["printed", "accepted", "allocated"]}
      // buttons_line={["Printed", "Accept Order", "Allocate Orders"]}
      // modal_fn={[
      //   {
      //     heading: "Printed Orders",
      //     action: "Are You Sure Your Want to Mark This Order As Printed",
      //     ConfirmButton: "Confirm",
      //     color: "blue",
      //     toast_notification: "Order Printed",
      //   },
      //   {
      //     heading: "Accept Order",
      //     action: "Are You Sure You want to Accept This order",
      //     ConfirmButton: "Accept Order",
      //     color: "green",
      //     toast_notification: "Order Accepted",
      //   },
      //   {
      //     heading: "Allocate Order",
      //     action: "Are You Sure You want to Allocate This order",
      //     ConfirmButton: "Allocated Order",
      //     color: "green",
      //     toast_notification: "Order Allocated",
      //   },
      // ]}
      button_action={policy}
        buttons_line={buttons_name}
      modal_fn={button_modal}
      setPolicy={setPolicy}
      setButtonModal={setButtonModal}
      setButtonname={setButtonname}
      policy={policy}
      buttons_name={buttons_name}
      button_modal={button_modal}
    />
  );
};

export default RejectedOrders;
