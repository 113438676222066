import {
  Box, Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer, Divider, Flex, Image, Text, Button
} from "@chakra-ui/react";
import COD from "../../../images/COD.png"
import React, { useEffect, useRef, useState } from "react";
import './print.css';
import ReactToPrint from 'react-to-print';
import { useSelector } from "react-redux";
import fnp_logo from "../../../images/fnp_logo.jpg"

const DeliveryDetails = (props) => {

  const pdref = useRef();
  const partnerDetail = useSelector(
    (state) => state.VendorReducer.singlevendor[0]?.partner
  );

  let data = [partnerDetail?.accepted_orders?.find((item) => item._id === props.id)]
  // console.log(data, "detail")

  function ForFindingshippingMethod(arr) {
    let k
    arr[0]?.line_items.forEach((item) => {
      if (!item.properties.map((prop) => prop.name).includes("parent_id")) {
        item.properties.forEach((item) => {
          if (item.name === "_shipping_method") {
            k = item.value
          }
        })
      }
    })
    return k
  }
  //  // console.log(ForFindingshippingMethod(data),"jj")
  let [totalprice, setTotalprice] = useState(0)
  function calculatePrice() {
    let p = 0
    for (let i = 0; i < data[0].line_items.length; i++) {
      p += Number(data[0].line_items[i].price) * Number(data[0].line_items[i].quantity)
    }
    setTotalprice(p)
  }


  function findImagetoshow(obj) {
    let k = obj.properties.find((k) => k.name.trim() === "image")
    if (k) {
      return true
    }
    else {
      return false
    }
  }

  function messagetoshow(arr) {
    for (const item of arr) {
      const messageProperty = item.properties.find((property) => property.name.trim() === "message");

      if (messageProperty) {

        return messageProperty.value;
      }
    }

    return null; // Return null if no image property was found
  }

  function imagetoshow(arr) {
    for (const item of arr) {
      const imageProperty = item.properties.find((property) => property.name.trim() === "image");

      if (imageProperty) {

        return imageProperty.value;
      }
    }

    return null; // Return null if no image property was found
  }


  useEffect(() => {
    calculatePrice()

  }, [partnerDetail?.accepted_orders])
  return (
    <Box width="70%" margin="auto">
      <ReactToPrint
        trigger={() => <Button colorScheme="green">Print</Button>}
        content={() => pdref.current}
      />

      {/* *************************   FOR FNP LOGO AND QR *************************** */}
      <Box ref={pdref} width="95%" margin="auto" mt="20px">
        <Flex height="32vh" display="inline-block" width="100%">
          <Box width="50%"></Box>
          <Box width="50%" float="right" textAlign="left">
            {messagetoshow(data[0].line_items).replace(/"/g, "")}
          </Box>
        </Flex>
        <Divider
          variant="dashed"
          colorScheme="black"
          borderColor="black"
          borderWidth="1px"
        />
        <Flex justifyContent="space-between" p="0px 50px 0px 50px">
          <Flex fap="10px">
            <Box>
              <Image
                src={fnp_logo}
                alt="fnp"
                height="70px"
                width="150px"
                margin="10px auto"
              />
            </Box>
            {data[0].payment_gateway_names.includes(
              "Cash on Delivery (COD)"
            ) && <Image src={COD} />}
          </Flex>
          <Box></Box>
        </Flex>
        {/* *************************   FOR ORDER DETAIL *************************** */}
        <Divider colorScheme="black" borderColor="black" borderWidth="2px" />
        <Flex p="10px 50px 10px 50px" justifyContent="space-between">
          <Box textAlign="left">
            <Text fontWeight="bold" fontSize="18px">
              Deliver To :
            </Text>

            <Flex flexDirection="column">
              {data[0].shipping_address.split(",").map((item, i) => (
                <Text key={i}>{item}</Text>
              ))}
            </Flex>
          </Box>
          <Box textAlign="left">
            <Text fontWeight="semibold" fontSize="18px">
              Order : {data[0]?.name}
            </Text>
            {data[0].payment_gateway_names.includes(
              "Cash on Delivery (COD)"
            ) && (
              <Text fontWeight="semibold" fontSize="18px">
                Order Amount : {totalprice}
              </Text>
            )}
            <Text>Order Date : {data[0]?.created_at.split("T")[0]}</Text>
            <Text>Delivery Date :{data[0]?.to_be_shipped_on}</Text>
            <Text>Shipping :{ForFindingshippingMethod(data)} </Text>
            <Text>Time : {data[0]?.time_slot}</Text>
          </Box>
        </Flex>
        {/* *************************   FOR PRODUCT DETAIL *************************** */}

        <TableContainer mt="20px">
          <Table variant="simple">
            <Thead
              style={{
                border: "2px solid black",
                borderRadius: "5px",
                padding: "10px",
                textAlign: "center",
              }}
            >
              <Tr
                style={{
                  border: "2px solid black",
                  borderRadius: "5px",
                  padding: "10px",
                  textAlign: "center",
                }}
              >
                <Th
                  style={{
                    border: "2px solid black",
                    borderRadius: "5px",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  quantity
                </Th>
                <Th
                  style={{
                    border: "2px solid black",
                    borderRadius: "5px",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  Product Image
                </Th>
                <Th
                  style={{
                    border: "2px solid black",
                    borderRadius: "5px",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  product
                </Th>
                <Th
                  style={{
                    border: "2px solid black",
                    borderRadius: "5px",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  Product Description
                </Th>
                <Th
                  style={{
                    border: "2px solid black",
                    borderRadius: "5px",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  Uploaded Image
                </Th>
              </Tr>
            </Thead>
            {data[0].line_items.map((item, i) => (
              <Tbody
                key={i}
                alignItems="center"
                style={{
                  border: "2px solid black",
                  borderRadius: "5px",
                  padding: "10px",
                  textAlign: "center",
                }}
              >
                <Tr>
                  <Td
                    width="20px"
                    whiteSpace="normal"
                    wordBreak="break-word"
                    style={{
                      border: "2px solid black",
                      borderRadius: "5px",
                      padding: "10px",
                      textAlign: "center",
                      color: "red",
                      fontWeight: "semibold",
                      fontSize: "18px",
                    }}
                  >
                    {item?.fulfillable_quantity}
                  </Td>
                  <Td
                    width="100px"
                    whiteSpace="normal"
                    wordBreak="break-word"
                    style={{
                      border: "2px solid black",
                      borderRadius: "5px",
                      padding: "10px",
                      textAlign: "center",
                      margin: "auto",
                    }}
                  >
                    <Box width="90%">
                      <Image
                        src={
                          item?.properties.filter(
                            (k) => k.name.trim() === "product_image"
                          )[0]?.value
                        }
                        height="80px"
                        width="80px"
                        margin="auto"
                      />
                    </Box>
                  </Td>
                  <Td
                    width="200px"
                    whiteSpace="normal"
                    wordBreak="break-word"
                    style={{
                      border: "2px solid black",
                      borderRadius: "5px",
                      padding: "10px 0px 10px 30px",
                    }}
                  >
                    {item?.name}
                  </Td>
                  <Td
                    width="200px"
                    whiteSpace="normal"
                    wordBreak="break-word"
                    style={{
                      border: "2px solid black",
                      borderRadius: "5px",
                      padding: "10px",
                      textAlign: "center",
                    }}
                  ></Td>
                  <Td
                    width="200px"
                    whiteSpace="normal"
                    wordBreak="break-word"
                    style={{
                      border: "2px solid black",
                      borderRadius: "5px",
                      padding: "10px",
                      textAlign: "center",
                    }}
                  >
                    {findImagetoshow(item) &&
                      imagetoshow(data[0].line_items) !== "[]" && (
                        <Image
                          src={JSON.parse(
                            imagetoshow(data[0].line_items)
                          ).toString()}
                          height="100px"
                          width="100px"
                        />
                      )}
                  </Td>
                </Tr>
              </Tbody>
            ))}
          </Table>
        </TableContainer>
        <Text fontWeight="semibold" fontSize="18px" mt="20px" textAlign="left">
          Sender : {data[0]?.billing_address?.first_name}{" "}
          {data[0]?.billing_address?.last_name}
        </Text>
        {messagetoshow(data?.line_items).replace(/"/g, "") !== "" && (
          <Text fontWeight="semibold" fontSize="18px" textAlign="left">
            message on product :{" "}
            {messagetoshow(data[0]?.line_items).replace(/"/g, "")}
          </Text>
        )}
      </Box>
    </Box>
  );
};

export default DeliveryDetails;
