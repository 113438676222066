import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Grid,
  Heading,
  Input,
  Select,
  Spinner,
  Text,
  useToast,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Image,
} from "@chakra-ui/react";
import axios from "axios";
import { MdOutlineDateRange } from "react-icons/md";
import { setToast } from "../../../Fucntions/Toastfunction";
import ConfirmationModal from "../../../Fucntions/Modals/ConfirmationModal";
import { useLocation, useNavigate } from "react-router-dom";
import OrderCards from "./OrderCards";
import PaginationComp from "../../../Fucntions/Pagination/PaginationComp";
import { useDispatch } from "react-redux";
import SuborderPopupChallan from "../../../SuborderChallan/SuborderPopupChallan";
import { ChevronDownIcon } from "@chakra-ui/icons";
import cod_image from "../../../images/COD.png";

const OrderStatusComp = ({
  order_status,
  button_action,
  heading,
  selection,
  buttons_line,
  challanshow,
  modal_fn,
  status,
  remark,
  setPolicy,
  setButtonModal,
  setButtonname,
  policy,
  buttons_name,
  button_modal,
}) => {
  const datePick = useRef(null);
  const [allTime_slots, setAllTimeSlots] = useState([]);
  const location = useLocation();
  const [select_vendor, setSelect_Vendor] = useState(false);
  const todayDate = new Date().toISOString().split("T")[0];
  const headers = {
    Authorization: `Bearer ${JSON.parse(
      sessionStorage.getItem("partnertoken")
    )}`,
  };
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  const formattedTomorrow = tomorrow.toISOString().split("T")[0];
  const [partnerDetail, setPartnerDetail] = useState([]);
  const navigate = useNavigate();
  const toast = useToast();
  const [dateSelectedforFilter, setDateSelectedForFilter] = useState(null);
  const [checkbox, setCheckbox] = useState([]);
  const [page, setPage] = useState(1);
  const [orderSearch, setOrderSearch] = useState("");
  const [dataforAction, setDataforAction] = useState([]);
  const [selectedDates, setSelectedDates] = useState(null);

  let [datatosend, setDataTosend] = useState({
    status: `${status}`,
    search: false,
    date:
      location.search === "?filter=today"
        ? todayDate
        : location.search === "?filter=tomorrow"
        ? formattedTomorrow
        : location.search === "?filter=future"
        ? "future"
        : false,
    partner_id: JSON.parse(sessionStorage.getItem("id")),
    time_slot: false,
  });
  const id = JSON.parse(sessionStorage.getItem("id"));
  // *************************    FOR ACTION OF ORDER MODAL ************************************
  const [actionconfirm, setActionConfirm] = useState(false);
  const [modaldata, setModalData] = useState(null);
  let [dp_single_id, setDp_single_id] = useState(null);
  const openActionModal = (i) => {
    if (dataforAction.length === 0) {
      setDp_single_id(null);
      setToast(toast, "Please Select Any Order First", "", "error");
      return;
    }
    setModalData(i);
    setActionConfirm(true);
  };
  const closeActionModal = () => {
    setSelect_Vendor(false);
    setActionConfirm(false);
  };

  //  **************************** MODAL FOR ALL CHALLAN *****************************
  const [isAllChallan, setIsAllChallan] = useState(false);
  const openAllChallanModal = () => {
    setIsAllChallan(true);
  };
  const closeAllChallanModal = () => {
    setIsAllChallan(false);
  };

  function getAllTimeSlots() {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/shipping/partner/read`,
      headers,
    })
      .then((r) => {
        console.log(r.data);
        setAllTimeSlots(r.data.shipping_methods);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // ***********************    MOADAL FOR PRINT CHALLAN   ***************************
  const [challan, setChallan] = useState("");
  const [challanstatus, setChallanStatus] = useState(false);
  const openChallanBox = (id) => {
    let d = partnerDetail?.filter((k) => k._id === id);
    setChallan(d);
    setChallanStatus(true);
  };
  const closeChallanBox = () => {
    setChallanStatus(false);
  };

  function getAllTimeSlots() {
    const headers = {
      Authorization: `Bearer ${JSON.parse(
        sessionStorage.getItem("partnertoken")
      )}`,
    };
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/shipping/partner/read`,
      headers,
    })
      .then((r) => {
        console.log(r.data);
        setAllTimeSlots(r.data.shipping_methods);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  const [remark_notes, setremark_notes] = useState([]);

  function handleParentCheckbox(e) {
    if (partnerDetail?.length === checkbox.length) {
      setCheckbox([]);
      setDataforAction([]);
      setremark_notes([]);
    } else {
      let details = partnerDetail;
      let arr = details.map((item, i) => i);
      setCheckbox(arr);
      const itemarr = details.map((order) => order.suborder_id);
      setDataforAction(itemarr);
      let remarkarr = itemarr.map((item) => {
        return { id: item, remark: "Unable to contact" };
      });
      setremark_notes(remarkarr);
    }
  }

  function handlechildrenCheckbox(i, item) {
    if (checkbox.includes(i)) {
      let arr = [...checkbox];
      arr = arr.filter((index) => index !== i);
      setCheckbox(arr);
      let dataarr = [...dataforAction];
      dataarr = dataarr.filter((id) => id !== item.suborder_id);
      let remarkarr = dataarr.map((item) => {
        return { id: item, remark: "Unable to contact" };
      });
      setremark_notes(remarkarr);
      setDataforAction(dataarr);
      console.log(remarkarr, "2");
    } else {
      let arr = [...checkbox];
      arr.push(i);
      setCheckbox(arr);
      let datarr = [...dataforAction];
      datarr.push(item.suborder_id);
      setDataforAction(datarr);
      let remarkarr = datarr.map((item) => {
        return { id: item, remark: "Unable to contact" };
      });
      console.log(remarkarr, "1");
      setremark_notes(remarkarr);
    }
  }

  function searchByDateApiHit(searcheddate) {
    let data = {
      status: `${status}`,
      search: orderSearch === "" ? false : true,
      date: searcheddate,
      partner_id: JSON.parse(sessionStorage.getItem("id")),
      time_slot: datatosend.time_slot,
    };
    setDataTosend(data);
    if (isloading) {
      return;
    }
    setisLoading(true);
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/partner/order/${order_status}?page=${page}`,
      data: data,
      headers,
    })
      .then((r) => {
        setisLoading(false);
        setPartnerDetail(r.data.order);
        let access = r?.data?.policy.find((k) => k.status === status)?.access;
        settingModalButtonAndStatus(access);
      })
      .catch((err) => {
        setisLoading(false);
        console.log(err);
        setToast(toast, `${err.response.data.err}`, "", "error");
      });
  }

  const [selectedTimsSlot, setSelectedTimeSlot] = useState("Select Time Slot");
  function searchByTimeSlotsHitApi(time_slot) {
    let data = {
      status: `${status}`,
      search: false,
      date:
        location.search === "?filter=today"
          ? todayDate
          : location.search === "?filter=tomorrow"
          ? formattedTomorrow
          : location.search === "?filter=future"
          ? "future"
          : false,
      partner_id: JSON.parse(sessionStorage.getItem("id")),
      time_slot: time_slot,
    };
    setDataTosend(data);
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/partner/order/${order_status}?page=${page}`,
      data: data,
      headers,
    })
      .then((r) => {
        setPartnerDetail(r.data.order);
        let access = r.data.policy.find((k) => k.status === status)?.access;
        settingModalButtonAndStatus(access);
      })
      .catch((err) => {
        console.log(err);
        setToast(toast, `${err.response.data.err}`, "", "error");
      });
  }

  const [selectedDriver, setSelectedDriver] = useState("Delivery Boy");
  function searchByDriverAllocated(driver) {
    let data = {
      status: `${status}`,
      search: false,
      date: false,
      partner_id: JSON.parse(sessionStorage.getItem("id")),
      time_slot: false,
      driver: driver,
    };
    setDataTosend(data);
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/partner/order/${order_status}?page=${page}`,
      data: data,
      headers,
    })
      .then((r) => {
        setPartnerDetail(r.data.order);
        let access = r.data.policy.find((k) => k.status === status)?.access;
        settingModalButtonAndStatus(access);
      })
      .catch((err) => {
        console.log(err);
        setToast(toast, `${err.response.data.err}`, "", "error");
      });
  }

  let [dp_data, setDp_data] = useState([]);
  async function getAllDeliveryPartner() {
    try {
      let config = {
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/dp/all`,
        headers,
        data: { vendor_id: id },
      };

      let data = await axios.request(config);

      setDp_data(data.data.data);
    } catch (err) {
      console.log(err, "err in fetching all deliverypartner");
    }
  }

  function handleIdofPartner(i) {
    openActionModal(i);
  }

  const [dontnotify_customer, setDontNotify_customer] = useState(false);
  let [buttonLoader, setButtonLoader] = useState(false);
  const ButtontOrderHitApi = async () => {
    if (buttonLoader) {
      return;
    }
    setButtonLoader(true);
    let data = {
      partner_id: JSON.parse(sessionStorage.getItem("id")),
      status: `${button_action[modaldata]}`,
      suborder_ids: dataforAction,
      dp_id: dp_single_id,
      remark_notes,
      dontnotify_customer,
    };

    try {
      let response = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_API_URL}/suborder/multiple_update`,
        data,
        headers,
      });
      if (response.data.err) {
        setToast(toast, `${response.data.err}`, "", "error");
      } else {
        setToast(
          toast,
          `${modal_fn[modaldata]?.toast_notification}`,
          "",
          "success"
        );
      }
      console.log(response.data, "sakjdj");

      setCheckbox([]);
      setDataforAction([]);
      closeActionModal();
      setButtonLoader(false);
      fetchdata();
    } catch (err) {
      setButtonLoader(false);
      console.log(err, "err");
      if (err.response.data.err === "Invalid token") {
        sessionStorage.removeItem("partnertoken");
        navigate("partner/login");
        return;
      }
      setToast(toast, `${err.response.data.err}`, "", "error");
    }
    setSelect_Vendor(false);
  };
  const [isloading, setisLoading] = useState(false);
  async function fetchdata() {
    if (isloading) {
      return;
    }
    setisLoading(true);
    try {
      let data = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/partner/order/${order_status}?page=${page}`,
        data: datatosend,
        headers,
      });
      console.log(data.data, "+++++++++++++++++++++++++++++++++++");
      setPartnerDetail(data.data.order);
      setisLoading(false);
      let access = data.data.policy.find((k) => k.status === status)?.access;
      settingModalButtonAndStatus(access);
    } catch (err) {
      setisLoading(false);
      console.log(err);
      setToast(toast, `${err.response.data.err}`, "", "error");
    }
  }

  useEffect(() => {
    if (location.search === "?filter=today") {
      setDateSelectedForFilter(todayDate);
    } else if (location.search === "?filter=tomorrow") {
      setDateSelectedForFilter(formattedTomorrow);
    } else if (location.search === "?filter=future") {
      setDateSelectedForFilter("future");
    } else {
      setDateSelectedForFilter(null);
    }
    getAllDeliveryPartner();
  }, []);

  useEffect(() => {
    setDataTosend({
      status: `${status}`,
      search: false,
      date:
        location.search === "?filter=today"
          ? todayDate
          : location.search === "?filter=tomorrow"
          ? formattedTomorrow
          : location.search === "?filter=future"
          ? "future"
          : false,
      partner_id: JSON.parse(sessionStorage.getItem("id")),
      time_slot: false,
    });
    fetchdata();
    getAllTimeSlots();
  }, [page, dateSelectedforFilter, selectedDates]);

  const [allprintedloader, setAllprintedLoader] = useState(false);
  let [acceptedChallan, setAcceptedChallan] = useState([]);
  function getChallanDataForAcceptedOrder(prop) {
    if (allprintedloader) {
      return;
    }
    const ids = remark_notes.map((note) => note.id);
    if (!ids.length > 0) {
      setToast(toast, "Please select atleast one order", "", "error");
      return;
    }
    console.log(remark_notes, "opopopopo");
    setAllprintedLoader(true);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/partner/challan`,
      data: {
        partner_id: JSON.parse(sessionStorage.getItem("id")),
        status: status,
        all: prop,
        ids: ids,
      },
      headers,
    })
      .then((r) => {
        setAllprintedLoader(false);
        // console.log(r.data);
        setAcceptedChallan(r.data.order);
        openAllChallanModal();
      })
      .catch((err) => {
        setAllprintedLoader(false);
        console.log(err);
        setToast(toast, `${err.response.data.err}`, "", "error");
      });
  }

  // ********************* FUNCTION FOR SETTING POLICY AND MODAL AND BUTTON NAMES ***************************

  async function settingModalButtonAndStatus(data) {
    let policy_with_modal = [
      {
        status: "allocated",
        button_name: "Allocate Order",
        modal: {
          heading: "Allocate Order",
          action: "Are You Sure You want to Allocate This order",
          ConfirmButton: "Allocated Order",
          color: "green",
          toast_notification: "Order Allocated",
        },
      },
      {
        status: "accepted",
        button_name: "Accept Order",
        modal: {
          heading: "Accept Order",
          action: "Are You Sure You want to Accept This order",
          ConfirmButton: "Accept Order",
          color: "green",
          toast_notification: "Order Accepted",
        },
      },
      {
        status: "printed",
        button_name: "Printed",
        modal: {
          heading: "Printed Orders",
          action: "Are You Sure Your Want to Mark This Order As Printed",
          ConfirmButton: "Confirm",
          color: "blue",
          toast_notification: "Order Printed",
        },
      },
      {
        status: "rejected",
        button_name: "Reject Orders",
        modal: {
          heading: "Reject Order",
          action: "Are You Sure You want to Reject This order",
          ConfirmButton: "Reject Order",
          color: "red",
          toast_notification: "Order Rejected",
        },
      },
      {
        status: "driver_allocated",
        button_name: "Allocate Driver",
        modal: {
          heading: "Allocate To Driver",
          action: "Are You Sure Your Want to Allocate this order to",
          ConfirmButton: "Confirm",
          color: "blue",
          toast_notification: "Order Allocated To Driver",
        },
      },
      {
        status: "out_for_delivery",
        button_name: "Out For Delivery",
        modal: {
          heading: "Out For Delivery Order",
          action:
            "Are You Sure You want to Mark This order As Out For Delivery",
          ConfirmButton: "Mark As out For Delivery",
          color: "teal",
          toast_notification: "Order Out For Delivery",
        },
      },
      {
        status: "attempted",
        button_name: "Attempted",
        modal: {
          heading: "Attempted",
          action: "Are You Sure You want to Mark This Order As Attempted",
          ConfirmButton: "Attempted",
          color: "green",
          toast_notification: "Order Marked Attempted",
        },
      },
      {
        status: "fulfilled",
        button_name: "Delivered",
        modal: {
          heading: "Delivered Order",
          action: "Are You Sure You want to Mark This order As Delivered",
          ConfirmButton: "Mark As Delivered",
          color: "teal",
          toast_notification: "Order Delivered",
        },
      },
    ];
    let buttonName = [];
    let buttonModal = [];
    for (let i = 0; i < data?.length; i++) {
      let obj = policy_with_modal.find((k) => k.status === data[i]);
      buttonName.push(obj.button_name);
      buttonModal.push(obj.modal);
    }
    setPolicy(data);
    setButtonModal(buttonModal);
    setButtonname(buttonName);
  }

  let timeoutId = useRef(null);
  useEffect(() => {
    clearTimeout(timeoutId.current);
    if (!orderSearch) {
      timeoutId.current = setTimeout(() => {
        fetchdata();
      }, 1000);
    } else {
      let data = {
        status: `${status}`,
        search: orderSearch,
        date: dateSelectedforFilter,
        partner_id: JSON.parse(sessionStorage.getItem("id")),
        time_slot: false,
      };

      console.log(data, "datatata");
      timeoutId.current = setTimeout(() => {
        axios({
          method: "POST",
          url: `${process.env.REACT_APP_API_URL}/partner/order/${order_status}?page=${page}`,
          data: data,
          headers,
        })
          .then((r) => {
            console.log(r.data, "+++++++++++++++++++++++++++++++++++");
            setPartnerDetail(r?.data?.order);
            let access = r?.data?.policy?.find(
              (k) => k.status === status
            )?.access;
            settingModalButtonAndStatus(access);
          })
          .catch((err) => {
            console.log(err);
            setToast(toast, `${err.response.data.err}`, "", "error");
          });
      }, 1000);
    }
  }, [orderSearch]);

  return (
    <div>
      {modal_fn && (
        <ConfirmationModal
          isOpen={actionconfirm}
          onClose={closeActionModal}
          heading={modal_fn[modaldata]?.heading}
          action={modal_fn[modaldata]?.action}
          onClickFunction={ButtontOrderHitApi}
          ConfirmButton={modal_fn[modaldata]?.ConfirmButton}
          color={modal_fn[modaldata]?.color}
          loader={true}
          loading={buttonLoader}
          is_Select_vendor={select_vendor}
          notify={true}
          setDontNotify_customer={setDontNotify_customer}
        />
      )}

      <SuborderPopupChallan
        isOpen={isAllChallan}
        challan={acceptedChallan}
        onClose={closeAllChallanModal}
      />

      <SuborderPopupChallan
        challan={challan}
        isOpen={challanstatus}
        onClose={closeChallanBox}
      />
      <Flex
        width="100%"
        justifyContent="space-between"
        flexDir={{ base: "column", md: "row", lg: "row" }}
        p="10px"
        bg="gray.100"
        mb="20px"
        style={{
          position: "-webkit-sticky",
          position: "sticky",
          top: "0", // Stick to the top
          zIndex: "100", // Adjust z-index as needed
        }}
      >
        <Box>
          <Heading
            as="h3"
            size="lg"
            fontFamily="NexaText-Trial-Heavy"
            mb="20px"
          >
            {heading}
          </Heading>
          {partnerDetail.length > 0 ? (
            <>
              {selection && (
                <Flex alignItems="left" gap="10px" mb="10px">
                  <Checkbox
                    bg="white"
                    size="lg"
                    height="fit-content"
                    onChange={handleParentCheckbox}
                    isChecked={checkbox.length === partnerDetail.length}
                  />
                  <Text>Select All</Text>
                </Flex>
              )}
            </>
          ) : null}
          <Flex gap="15px">
            {/* {(order_status === "allocated_orders" ||
            order_status === "accepted_orders") && ( */}
            <Select
              width="150px"
              value={selectedDates}
              bg="white"
              onChange={(e) => {
                if (e.target.value === "today") {
                  setDataTosend({ ...datatosend, date: todayDate });
                  setSelectedDates("today");
                  navigate(`${location.pathname}?filter=today`);
                } else if (e.target.value === "tomorrow") {
                  setDateSelectedForFilter("tomorrow");
                  setSelectedDates("tomorrow");
                  setDataTosend({
                    ...datatosend,
                    date: formattedTomorrow,
                  });
                  navigate(`${location.pathname}?filter=tomorrow`);
                } else if (e.target.value === "future") {
                  setDateSelectedForFilter("future");
                  setSelectedDates("future");
                  setDataTosend({
                    ...datatosend,
                    date: "future",
                    today: todayDate,
                    tomorrow: formattedTomorrow,
                  });
                  navigate(`${location.pathname}?filter=future`);
                } else {
                  setDataTosend({ ...datatosend, date: null });
                  setSelectedDates("all");
                  navigate(`${location.pathname}`);
                }
              }}
            >
              <option value="">
                {location.search === "?filter=today"
                  ? "Today"
                  : location.search === "?filter=tomorrow"
                  ? "Tomorrow"
                  : location.search === "?filter=future"
                  ? "Future"
                  : "All"}
              </option>
              <option value="all">All</option>
              <option value="today">Today</option>
              <option value="tomorrow">Tomorrow</option>
              <option value="future">Future</option>
            </Select>
            {/* // )} */}
            <Button bg="white">
              <label for="#78342345" style={{ fontSize: "26px" }}>
                <MdOutlineDateRange />
              </label>
              <input
                type="date"
                pattern="\d{2}-\d{2}-\d{4}"
                id="#78342345"
                class="datepicker"
                onChange={(e) => searchByDateApiHit(e.target.value)}
                name="datepicker"
              />
              <input type="date" style={{ display: "none" }} ref={datePick} />
            </Button>
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<ChevronDownIcon />}
                bg="white"
                width="max-content"
              >
                {selectedTimsSlot}
              </MenuButton>
              <MenuList style={{ maxHeight: "300px", overflowY: "auto" }}>
                <MenuItem
                  onClick={() => {
                    searchByTimeSlotsHitApi(false);
                    setSelectedTimeSlot("Select Time Slot");
                  }}
                >
                  remove selected
                </MenuItem>
                {allTime_slots
                  .flatMap((k) => k.time_slots)
                  .map((item) => (
                    <MenuItem
                      onClick={() => {
                        setSelectedTimeSlot(
                          item.start + " " + "-" + " " + item.end
                        );
                        searchByTimeSlotsHitApi(
                          item.start + " " + "-" + " " + item.end
                        );
                      }}
                      value={item.start + " " + "-" + " " + item.end}
                    >
                      {item.start} - {item.end}
                    </MenuItem>
                  ))}
              </MenuList>
            </Menu>
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<ChevronDownIcon />}
                bg="white"
                width="max-content"
              >
                {selectedDriver}
              </MenuButton>
              <MenuList style={{ maxHeight: "300px", overflowY: "auto" }}>
                {dp_data.map((item) => (
                  <MenuItem
                    value={item.name}
                    onClick={() => {
                      searchByDriverAllocated(item.name);
                      setSelectedDriver(item.name);
                    }}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </Flex>
        </Box>
        <Flex gap="10px" flexDir="column" mt="30px">
          <Flex gap="10px" justifyContent="flex-end">
            {/* order rejecting acception button */}
            <Flex gap="10px">
              {challanshow && (
                <Button
                  bg="#A1BE28"
                  color="white"
                  fontFamily="NexaText-Trial-Heavy"
                  pt="6px"
                  _hover={{
                    bg: "white",
                    color: "#A1BE28",
                    border: "1px solid #A1BE28",
                  }}
                  onClick={() => {
                    getChallanDataForAcceptedOrder(true);
                  }}
                >
                  {allprintedloader ? <Spinner /> : "Print Challan"}
                </Button>
              )}

              {buttons_line.length > 0 && (
                <Menu>
                  <MenuButton
                    bg="#A1BE28"
                    color="white"
                    fontFamily="NexaText-Trial-Heavy"
                    pt="6px"
                    _hover={{
                      bg: "white",
                      color: "#A1BE28",
                      border: "1px solid #A1BE28",
                    }}
                    as={Button}
                    rightIcon={<ChevronDownIcon />}
                  >
                    Change Status
                  </MenuButton>
                  <MenuList>
                    {buttons_line.map((button, i) => {
                      if (button === "Allocate Driver") {
                        return (
                          <Select
                            placeholder="Allocated Driver"
                            value={setDp_single_id}
                            onChange={(e) => {
                              setDp_single_id(e.target.value);
                              setSelect_Vendor(true);
                              sessionStorage.setItem(
                                "dp_name",
                                dp_data.find((id) => id._id === e.target.value)
                                  ?.name
                              );
                              handleIdofPartner(i);
                            }}
                          >
                            {dp_data.map((dp, index) => (
                              <option key={index} value={dp._id}>
                                {dp.name}
                              </option>
                            ))}
                          </Select>
                        );
                      } else {
                        return (
                          <MenuItem key={i} onClick={() => openActionModal(i)}>
                            {button}
                          </MenuItem>
                        );
                      }
                    })}
                  </MenuList>
                </Menu>
              )}
            </Flex>
          </Flex>
          <Flex justifyContent="flex-end" gap="20px">
            <Input
              mt="10px"
              placeholder="search order name"
              onChange={(e) => setOrderSearch(e.target.value)}
              width="180px"
              bg="white"
              flex="flexEnd"
            />
            {/* // pagination button */}
            <PaginationComp
              page={page}
              setPage={setPage}
              isDisabledLogic={partnerDetail.length < 25 || isloading}
            />
          </Flex>
        </Flex>
      </Flex>
      {isloading ? (
        <Spinner size="xl" marginTop="50px" fontWeight="bold" />
      ) : (
        <Box>
          {partnerDetail.length === 0 ? (
            <Text
              fontSize="35px"
              fontWeight="semibold"
              color="red.300"
              mt="200px"
            >
              No {heading}
            </Text>
          ) : (
            <Grid
              templateColumns={{
                base: "repeat(1,1fr)",
                md: "repeat(1,1fr)",
                lg: "repeat(1,1fr",
              }}
              gap="20px"
            >
              {partnerDetail.map((item, i) => {
                if (item.status !== status) return null;
                return (
                  <Box
                    key={i}
                    boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
                    borderRadius="7px"
                    bg="white"
                  >
                    {/* ***************************************   HEADING AND ID OF INDIVIDUAL ORDER  ********************************************* */}
                    <Flex
                      // bg="#edf26a"
                      bg="#A9BE79"
                      justifyContent="space-between"
                      alignItems="center"
                      p={
                        item.financial_status === "pending"
                          ? "0px 10px 0px 10px"
                          : "20px 10px 20px 10px"
                      }
                      fontWeight="bold"
                    >
                      <Flex gap="10px">
                        {selection && (
                          <Checkbox
                            bg="white"
                            size="lg"
                            onChange={() => handlechildrenCheckbox(i, item)}
                            height="fit-content"
                            isChecked={checkbox?.includes(i)}
                          />
                        )}
                        <Text alignItems="center">{item.name}</Text>
                        <Text alignItems="center">({item.suborder_id})</Text>
                      </Flex>
                      <Flex>
                        {item.financial_status === "pending" && (
                          <Image
                            src={cod_image}
                            height="60px"
                            width="60px"
                            margin="auto"
                          />
                        )}
                      </Flex>
                    </Flex>
                    <Box width="100%">
                      <OrderCards
                        item={item}
                        i={i}
                        remark={remark}
                        showinput={dataforAction.includes(item.suborder_id)}
                        remark_notes={remark_notes}
                        setremark_notes={setremark_notes}
                      />
                    </Box>
                    <Flex
                      justifyContent="space-between"
                      width="90%"
                      margin="auto"
                      mb="20px"
                    >
                      <></>

                      {challanshow && (
                        <Button onClick={() => openChallanBox(item._id)}>
                          view challan
                        </Button>
                      )}
                    </Flex>
                  </Box>
                );
              })}
            </Grid>
          )}
        </Box>
      )}
      {/* //pagination button */}
      <PaginationComp
        page={page}
        setPage={setPage}
        isDisabledLogic={partnerDetail?.length < 25 || isloading}
      />
    </div>
  );
};

export default OrderStatusComp;
