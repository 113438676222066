import React, { useState } from "react";
import {
  FormControl,
  FormLabel,
  Heading,
  Box,
  Input,
  Flex,
  Button,
  useToast,
  InputGroup,
  InputRightElement,
  Spinner,
} from "@chakra-ui/react";
import { MdKeyboardBackspace } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { PostVendorDetails } from "../../../redux/Admin/AppReducer/vendors/action";
import { setToast } from "../../../Fucntions/Toastfunction";

const CreateVendor = () => {
  const [passshow, setpassShow] = React.useState(false)
  const handlePassShowClick = () => setpassShow(!passshow)

  const [show, setShow] = React.useState(false)
  const handleClick = () => setShow(!show)

  const toast=useToast()
  const [vendordetail,setVendorDetail]=useState({
    partner_email:"",
    "partner_fname":'',
    "partner_lname":'',
    "partner_password":"",
    partner_confirm_password:"",
    partner_phone:"",
    partner_id:""
  })
  //(vendordetail,'detail')
  const dispatch=useDispatch()
  const navigate=useNavigate()

  function handlechange(e){
    const {name,value}=e.target;
    
    // Check if the first character is a space
    if (value.charAt(0) === ' ') {
      // Remove the first character (space)
      e.target.value = value.slice(1);
      return
    }
      if(name==="partner_confirm_password"){
        if(e.nativeEvent?.inputType==="deleteContentBackward"){
          setVendorDetail({
            ...vendordetail,
            [name]:value
          })
          return
        }
      if(vendordetail.partner_password===""){
        setToast(toast,"Enter your password First","","error")
        return
      }
      }
    setVendorDetail({
      ...vendordetail,
      [name]:value
    })
  }
  
const [loader,setLoader]=useState(false)
 async function handleSubmit(){
  if(vendordetail.partner_password!==vendordetail.partner_confirm_password){
   setToast(toast,"Password Doesn't Match","","error")
   return
  }
  setLoader(true)
    const param={
      "partner_name":vendordetail.partner_fname+" "+vendordetail.partner_lname,
      "partner_email":vendordetail.partner_email,
      "partner_password":vendordetail.partner_password,
      "partner_id":vendordetail.partner_id,
      partner_phone:vendordetail.partner_phone
    }
    ////(param,"param")
 await dispatch(PostVendorDetails(`${process.env.REACT_APP_API_URL}/partner/create`,param,toast,navigate,setLoader))
    
  }


  return (
    // MAIN BOX
    <Box width={{base:"95%",md:"80%",lg:"60%"}} margin="auto"
    fontSize={{base:"12px",md:"16px",lg:"16px"}}>
      {/* VENDOR PERSONAL DETAIL */}
      <Flex mb="20px" alignItems="center" gap="20px">
              <Button  onClick={()=>navigate(-1)}
              variant="outline" 
              padding="10px" border="1px solid grey">
                <MdKeyboardBackspace fontSize="30px" />
              </Button>
              <Heading
                as="h3"
                size="xl"
                fontWeight="medium"
                mb="10px"
                textAlign="left"
              >
                Create Vendor
              </Heading>
            </Flex>
      <Box
        boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
        p="6"
        bg="white"
        borderRadius="10px"
        paddingTop="2rem"
      >
         <Heading as='h3' size='lg'>
    Vendor Details
  </Heading>
        <Flex gap="10px" mt="20px">
        <FormControl>
          <FormLabel>First Name</FormLabel>
          <Input placeholder="Enter First Name" 
           name="partner_fname"  onChange={handlechange}/>
        </FormControl>
        <FormControl>
          <FormLabel>Last Name</FormLabel>
          <Input placeholder="Enter Last Name"   name="partner_lname" onChange={handlechange}/>
        </FormControl>
        </Flex>
        <FormControl mt="20px">
          <FormLabel>Email</FormLabel>
          <Input placeholder="Enter Email"   name="partner_email" onChange={handlechange}/>
        </FormControl> 
        {/* <FormControl mt="20px">
          <FormLabel>vendor Id</FormLabel>
          <Input placeholder="Enter Vendor id"    name="partner_id" onChange={handlechange}/>
        </FormControl>  */}
        <FormControl mt="20px">
          <FormLabel>Password</FormLabel>
          <InputGroup size='md'>
      <Input
        pr='4.5rem'
        type={show ? 'text' : 'password'}
        placeholder="Enter Your Password"   
           name="partner_password" onChange={handlechange}
      />
      <InputRightElement width='4.5rem'>
        <Button h='1.75rem' size='sm' onClick={handleClick}>
          {show ? 'Hide' : 'Show'}
        </Button>
      </InputRightElement>
    </InputGroup>
         
        </FormControl>
        <FormControl mt="20px">
          <FormLabel> Confirm Password</FormLabel>
          <InputGroup size='md'>
      <Input
        pr='4.5rem'
        type={passshow ? 'text' : 'password'}
        placeholder="Enter Your Password" 
            name="partner_confirm_password"
            onChange={handlechange}
      />
      <InputRightElement width='4.5rem'>
        <Button h='1.75rem' size='sm' onClick={handlePassShowClick}>
          {passshow ? 'Hide' : 'Show'}
        </Button>
      </InputRightElement>
    </InputGroup>
         
        </FormControl>
        <FormControl mt="20px">
          <FormLabel>Phone Number</FormLabel>
          <Input placeholder="Enter Phone Number" type="number"  name="partner_phone" 
          onChange={handlechange}/>
        </FormControl> 
        <Button colorScheme="green" 
        mt="20px"
        onClick={handleSubmit}>{loader?<Spinner/>:"Save"}</Button>
      </Box>

    </Box>
  );
};

export default CreateVendor;
