

import React, { useState } from "react";
import OrderStatusComp from "./OrderStatusComp";
import { Text } from '@chakra-ui/react'

const OutForDelivery = () => {
  let [policy, setPolicy] = useState([]);
  let [buttons_name, setButtonname] = useState([]);
  let [button_modal, setButtonModal] = useState([]);
  return (
    <div>
  
      <OrderStatusComp
      status={"out_for_delivery"}
        order_status={"outForDelivery_orders"}
        heading={"Out For Delivery Orders"}
        selection={true}
        challanshow={false}
        remark={true}
        // button_action={["driver_allocated","attempted","fulfilled"]}
        // buttons_line={["Allocate Driver","Attempted","Delivered"]}
        // modal_fn={[
        //   {
        //     heading: "Allocate To Driver",
        //     action: "Are You Sure Your Want to Allocate this order to",
        //     ConfirmButton: "Confirm",
        //     color: "blue",
        //     toast_notification: "Order Allocated To Driver",
        //   },
        //   {
        //     heading: "Attempted",
        //     action: "Are You Sure You want to Mark This Order As Attempted",
        //     ConfirmButton: "Attempted",
        //     color: "green",
        //     toast_notification: "Order Marked Attempted",
        //   },
        //   {
        //     heading: "Delivered Order",
        //     action: "Are You Sure You want to Mark This order As Delivered",
        //     ConfirmButton: "Mark As Delivered",
        //     color: "teal",
        //     toast_notification: "Order Delivered",
        //   },
        // ]}
        button_action={policy}
        buttons_line={buttons_name}
        modal_fn={button_modal}
        setPolicy={setPolicy}
        setButtonModal={setButtonModal}
        setButtonname={setButtonname}
        policy={policy}
        buttons_name={buttons_name}
        button_modal={button_modal}
      />
    </div>
  );
};

export default OutForDelivery;
