import { AddIcon } from "@chakra-ui/icons";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Box,
  Flex,
  Heading,
  Button,
  Text,
  Input,
  Modal,
  FormControl,
  FormLabel,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Td,
  TableContainer,
  useDisclosure,
  Checkbox,
  useToast,
  IconButton,
  Grid,
  Spinner,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import RolesModal from "./RolesModal";
import axios from "axios";
import { CiEdit } from "react-icons/ci";
import { AiOutlineDelete } from "react-icons/ai";
import ConfirmationModal from "../../../../Fucntions/Modals/ConfirmationModal";
import { setToast } from "../../../../Fucntions/Toastfunction";
import { useNavigate } from "react-router-dom";

const PartnerRoles = () => {
  const [roleid, setRoleid] = useState(null); // setting role id for editing and deleting
  const [render, setRender] = useState(false); // for rendrig after creating and deleting the roles
  const toast = useToast();
  const navigate = useNavigate();
  let initialRole = {
    name: "",
    description: "",
    policy: [
      {
        status: "allocated",
        access: [],
      },
      {
        status: "accepted",
        access: [],
      },
      {
        status: "printed",
        access: [],
      },
      {
        status: "rejected",
        access: [],
      },
      {
        status: "driver_allocated",
        access: [],
      },

      {
        status: "out_for_delivery",
        access: [],
      },
      {
        status: "fulfilled",
        access: [],
      },
      {
        status: "attempted",
        access: [],
      },
    ],
  };
  const [roleDetail, setRoleDetail] = useState(initialRole); // role detail for roles of user
  const [roles, setRoles] = useState([]); // setting roles to show all roles
  // ***********************   FOR CONFIRMING THE DELTE FUNCTION TO OPEN AND CLOSE MODAL  *****************************
  const [confirmisOpen, setConfirmisOpen] = useState(false); // confirmation modal to confirm to delete or not
  const openConfirmModal = (id) => {
    setConfirmisOpen(true);
    setRoleid(id);
  };
  const closeConfirmModal = () => setConfirmisOpen(false);

  const headers = {
    Authorization: `Bearer ${JSON.parse(
      sessionStorage.getItem("partnertoken")
    )}`,
  };

  // *****************************   FOR CREATING ROLE MODAL *******************************
  const [modalopen, setModalOpen] = useState(false);
  const onOpenModalrole = () => {
    setRoleDetail(initialRole);
    setModalOpen(true);
  };
  const onCloseModalrole = () => {
    setModalOpen(false);
  };

  // *****************************   FOR Editing ROLE MODAL *******************************
  const [editmodalopen, seteditModalOpen] = useState(false);
  const onOpeneditModalrole = (item) => {
    setRoleDetail(item);
    setRoleid(item._id);
    seteditModalOpen(true);
  };

  const onCloseeditModalrole = () => {
    seteditModalOpen(false);
  };

  // *******************   FUNCTION TO GET ALL THE ROLES *************************
  function getAllRoles() {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/partner/role/all`,
      headers,
    })
      .then((r) => {
        console.log(r.data);
        setRoles(r.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // ***********************   FUNCTION TO DELETE ROLES  *************************
  const [deleteloader, setdeleteLoader] = useState(false);
  function handleDeleteRoleButtonClickedApiHit() {
    console.log("hit");
    if (deleteloader) {
      return;
    }
    setdeleteLoader(true);
    axios({
      method: "DELETE",
      url: `${process.env.REACT_APP_API_URL}/partner/role/delete`,
      headers,
      data: {
        id: roleid,
      },
    })
      .then((r) => {
        setdeleteLoader(false);
        setToast(toast, "Role Deleted Successfully", "", "success");
        setRender(!render);
        closeConfirmModal();
      })
      .catch((err) => {
        if (err.response.data.err === "Invalid token") {
          sessionStorage.removeItem("partnertoken");
          navigate("/partner/login");
          return;
        }
        setdeleteLoader(false);
        setToast(toast, `${err.response.data.err}`, "", "error");
      });
  }

  useEffect(() => {
    getAllRoles();
  }, [render]);
  return (
    <Box width="90%" margin="auto">
      <ConfirmationModal
        heading="Delete Role"
        action="Are you Sure? You want to Delete this Role"
        ConfirmButton="Yes Delete"
        onClickFunction={handleDeleteRoleButtonClickedApiHit}
        isOpen={confirmisOpen}
        onClose={closeConfirmModal}
        loader={true}
        loading={deleteloader}
      />
      <RolesModal
        onClose={onCloseModalrole}
        isOpen={modalopen}
        roleDetail={roleDetail}
        setRoleDetail={setRoleDetail}
        render={render}
        setRender={setRender}
      />

      <RolesModal
        onClose={onCloseeditModalrole}
        isOpen={editmodalopen}
        roleDetail={roleDetail}
        setRoleDetail={setRoleDetail}
        render={render}
        setRender={setRender}
        edit={true}
      />
      <Flex justifyContent="space-between">
        <Heading as="h4" size="lg" fontWeight="medium">
          Roles
        </Heading>
        <Flex gap="10px">
          <Button
            leftIcon={<AddIcon />}
            colorScheme="green"
            onClick={onOpenModalrole}
          >
            Add Roles
          </Button>
        </Flex>
      </Flex>

      <div
        style={{
          borderRadius: "7px",
          boxShadow:
            "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
          backgroundColor: "white",
          marginTop: "30px",
        }}
        height="30px"
        width="30px"
      >
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>
                  <Text>S.No</Text>
                </Th>
                <Th>
                  <Text fontSize="sm">Name</Text>
                </Th>
                <Th>
                  <Text fontSize="sm">Description</Text>
                </Th>

                <Th>
                  <Text fontSize="sm" paddingLeft="20px">
                    Action
                  </Text>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {roles?.map((item, i) => (
                <Tr key={i}>
                  <Td width="10px" whiteSpace="normal" wordBreak="break-word">
                    {i + 1}
                  </Td>
                  <Td width="260px" whiteSpace="normal" wordBreak="break-word">
                    {item.name}
                  </Td>

                  <Td
                    maxWidth="600px"
                    whiteSpace="normal"
                    wordBreak="break-word"
                  >
                    {item.description}
                  </Td>
                  <Td>
                    {item.name !== "Super Admin" ? (
                      <Flex gap="30px">
                        <IconButton
                          color="blue.500"
                          onClick={() => onOpeneditModalrole(item)}
                          icon={<CiEdit fontSize="30px" />}
                          variant="outline"
                        />
                        <IconButton
                          color="red.500"
                          onClick={() => openConfirmModal(item._id)}
                          icon={<AiOutlineDelete fontSize="30px" />}
                          variant="outline"
                        />
                      </Flex>
                    ) : null}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </div>
    </Box>
  );
};

export default PartnerRoles;
