import React, { useEffect, useState } from "react";
import {
  Input,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Heading,
  Button,
  Box,
} from "@chakra-ui/react";
import axios from "axios";
import { useToast } from "@chakra-ui/react";

const Settings = () => {
  const [loading, setLoading] = useState(false);
  const [settings, setSettings] = useState();
  const [deliveryPartnerDays, setDeliveryPartnerDays] = useState(10);

  const toast = useToast();

  const fetchAllSettings = async () => {
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/settings`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await axios.request(config);
      console.log("Response Data:", response.data);
      setSettings(response.data.data);
      setDeliveryPartnerDays(
        response.data.data[0].deliveryPartnerOrderDays.days
      );
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const updateSettings = async () => {
    console.log("Entyering");
    setLoading(true);
    const data = {
      deliveryPartnerOrderDays: {
        days: deliveryPartnerDays,
        pseudoId: "deliveryPartnerOrderDays",
      },
    };

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/settings`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    try {
      const response = await axios.request(config);
      setLoading(false);
      toast({
        title: "Data Saved",
        description: "Successfully save the configurations",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      console.log("Response Data:", response.data);
    } catch (error) {
      setLoading(false);
      toast({
        title: "Error Occured",
        description: "Something went wrong",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchAllSettings();
  }, []);

  return (
    <div>
      <Heading>Settings</Heading>

      <div
        style={{ display: "flex", alignItems: "right", justifyContent: "end" }}
      >
        <Button
          colorScheme="teal"
          size="sm"
          isLoading={loading}
          loadingText="Saving"
          onClick={() => updateSettings()}
        >
          Save
        </Button>
      </div>
      <TableContainer>
        <Table variant="simple">
          {/* <TableCaption>All settings</TableCaption> */}
          {/* <Thead>
            <Tr>
              <Th>To convert</Th>
              <Th>into</Th>
              <Th >multiply by</Th>
            </Tr>
          </Thead> */}
          <Tbody>
            <Tr>
              <Td>Add the number of days delivery partner can see the order</Td>
              <Td>
                <Input
                  htmlSize={5}
                  type="number"
                  width="auto"
                  border={"1px solid"}
                  // defaultValue={10}
                  value={deliveryPartnerDays}
                  onChange={(e) => setDeliveryPartnerDays(e.target.value)}
                />
              </Td>
            </Tr>
            {/* <Tr>
              <Td>feet</Td>
              <Td>centimetres (cm)</Td>
              <Td isNumeric>30.48</Td>
            </Tr>
            <Tr>
              <Td>yards</Td>
              <Td>metres (m)</Td>
              <Td isNumeric>0.91444</Td>
            </Tr> */}
          </Tbody>
          {/* <Tfoot>
            <Tr>
              <Th>To convert</Th>
              <Th>into</Th>
              <Th isNumeric>multiply by</Th>
            </Tr>
          </Tfoot> */}
        </Table>
      </TableContainer>
    </div>
  );
};

export default Settings;
