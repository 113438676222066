// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media print {
    /* Adjust the button height for printing */
    .print-button {
      height: 40px; /* Adjust the height as needed */
    }
  }
  @media print {
    @page {
      margin-top: 20px;
  }
    .print-content {
      margin-top: 0;
      page-break-inside: avoid;
      page-break-after: always; /* Force page break after each print-content element */
      /* Add any other custom styles for printing */
    }
  }`, "",{"version":3,"sources":["webpack://./src/SuborderChallan/print.css"],"names":[],"mappings":"AAAA;IACI,0CAA0C;IAC1C;MACE,YAAY,EAAE,gCAAgC;IAChD;EACF;EACA;IACE;MACE,gBAAgB;EACpB;IACE;MACE,aAAa;MACb,wBAAwB;MACxB,wBAAwB,EAAE,sDAAsD;MAChF,6CAA6C;IAC/C;EACF","sourcesContent":["@media print {\n    /* Adjust the button height for printing */\n    .print-button {\n      height: 40px; /* Adjust the height as needed */\n    }\n  }\n  @media print {\n    @page {\n      margin-top: 20px;\n  }\n    .print-content {\n      margin-top: 0;\n      page-break-inside: avoid;\n      page-break-after: always; /* Force page break after each print-content element */\n      /* Add any other custom styles for printing */\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
