import React from "react";

import { Avatar, Box, Button, Flex, IconButton, Image, Text, useToast } from "@chakra-ui/react";

import fnp_logo from "../../images/fnp_logo.jpg";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { partnerLogOut } from "../../redux/AuthReducer/action";
import { useNavigate } from "react-router-dom";
import { RiLoginCircleFill, RiLogoutCircleRFill} from "react-icons/ri";
import PartnerSideDrawer from "./PartnerSideDrawer";

const PartnerNavbar = () => {

  const dispatch = useDispatch();
  const toast = useToast();
  const navigate = useNavigate();
  const partnerdetails = useSelector((state) => state.AuthReducer);


  function handleLogout() {
    dispatch(partnerLogOut(toast));
  }
  return (
    <Flex justifyContent="space-between" paddingLeft="20px" paddingRight="10px">
      <Flex gap="40px">
        <Box display={{ base: "none", md: "none", lg: "block" }}>
          <Image
            src={fnp_logo}
            alt="fnp"
            height="70px"
            width="150px"
            margin="10px auto"
          />
        </Box>
        <Box display={{ base: "block", md: "block", lg: "none" }}>
          <PartnerSideDrawer />
        </Box>
      </Flex>
      <Flex
        direction={{ base: "column", md: "row", lg: "row" }}
        alignItems="center"
      >
        <Box mr="15px"></Box>
        <Flex gap="10px" alignItems="center">
          {/* <ReasonModal button="refund"
          title="reason for Refund"
          placeholder="Write reason for refund"
          
          /> */}
          {partnerdetails?.PartnerisAuth ? (
            <IconButton
              onClick={handleLogout}
              colorScheme="red"
              icon={
                <RiLogoutCircleRFill
                  style={{
                    fontFamily: "NexaText-Trial-Heavy",
                    fontSize: "30px",
                  }}
                />
              }
            />
          ) : (
            <IconButton
              colorScheme="green"
              onClick={() => navigate("/partner/login")}
              icon={
                <RiLogoutCircleRFill
                  style={{
                    fontFamily: "NexaText-Trial-Heavy",
                    fontSize: "30px",
                  }}
                />
              }
            />
          )}

          <Flex direction="column" textAlign="left">
            <Text>
              {sessionStorage.getItem("email")
                ? JSON.parse(sessionStorage.getItem("email"))
                : null}
            </Text>
            <Text>
              {sessionStorage.getItem("id")
                ? JSON.parse(sessionStorage.getItem("id"))
                : null}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default PartnerNavbar;
