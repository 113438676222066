import React, { useEffect, useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Box,
  Flex,
  Heading,
  Button,
  Text,
  Td,
  TableContainer,
  useToast,
  IconButton,
} from "@chakra-ui/react";
import { CiEdit } from "react-icons/ci";
import { AiOutlineDelete } from "react-icons/ai";
import { AddIcon } from "@chakra-ui/icons";
import { setToast } from "../../../../Fucntions/Toastfunction";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import UserModal from "./UserModal";
import ConfirmationModal from "../../../../Fucntions/Modals/ConfirmationModal";

const PartnerUser = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const [updatebutton, setUpdateButton] = useState(false);
  const [userDetail, setUserDetail] = useState({
    name: "",
    email: "",
    password: "",
    confirm_password: "",
    role: [],
  });
  const [userid, setUserid] = useState(null);
  const [roles, setRoles] = useState([]); // setting roles to show all roles
  const [render, setRender] = useState(false);
  const [users, setUsers] = useState([]);
  const [addusermodal, setAdduserModal] = useState(false);
  const openAdduserModal = () => {
    setUserDetail({
      name: "",
      email: "",
      password: "",
      confirm_password: "",
      role: [],
    })
    setAdduserModal(true);
  };
  console.log(users,"users")
  const closeAdduserModal = () => setAdduserModal(false);
  const headers = {
    Authorization: `Bearer ${JSON.parse(
      sessionStorage.getItem("partnertoken")
    )}`,
  };

  const [editusermoadal, setEdituserModal] = useState(false);
  const openEdituserModal = (item) => {
    setUserDetail(item)
    setEdituserModal(true);
    setUpdateButton(true);
  };

  const closeEdituserModal = () => {
    setEdituserModal(false);
    setUpdateButton(false);
  };

  function getUserDetailHitApi() {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/partner/users/all`,
      headers,
    })
      .then((r) => {
        setUsers(r.data);
      })
      .catch((err) => {
        if (err.response.data.err === "Invalid token") {
          sessionStorage.removeItem("admintoken");
          navigate("/admin/login");
          return;
        }
        setToast(toast, `${err.response.data.err}`, "", "error");
      });
  }

  function getAllRoles() {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/partner/role/all`,
      headers,
    })
      .then((r) => {
        setRoles(r.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  const [confirmisOpen, setConfirmisOpen] = useState(false); // confirmation modal to confirm to delete or not
  const closeConfirmModal = () => setConfirmisOpen(false);
  const openConfirmModal = (id) => {
    setConfirmisOpen(true);
    setUserid(id);
  };

  const [deleteloader, setdeleteLoader] = useState(false);
  function handleDeleteUserButtonClickedApiHit() {
    if (deleteloader) {
      return;
    }
    setdeleteLoader(true);
    axios({
      method: "DELETE",
      url: `${process.env.REACT_APP_API_URL}/partner/users/delete/${userid}`,
      headers,
    })
      .then((r) => {
        setdeleteLoader(false);
        setToast(toast, "user Deleted Successfully", "", "success");
        setRender(!render);
        closeConfirmModal();
      })
      .catch((err) => {
        if (err.response.data.err === "Invalid token") {
          sessionStorage.removeItem("partnertoken");
          navigate("/partner/login");
          return;
        }
        setdeleteLoader(false);
        setToast(toast, `${err.response.data.err}`, "", "error");
      });
  }

  useEffect(() => {
    getUserDetailHitApi();
    getAllRoles();
  }, [render]);

  return (
    <>
      <UserModal
        onClose={closeAdduserModal}
        isOpen={addusermodal}
        render={render}
        setRender={setRender}
        userDetail={userDetail}
        setUserDetail={setUserDetail}
        roles={roles}
      />

      <UserModal
        onClose={closeEdituserModal}
        isOpen={editusermoadal}
        render={render}
        setRender={setRender}
        userDetail={userDetail}
        setUserDetail={setUserDetail}
        roles={roles}
        edit={true}
      />
      <ConfirmationModal
        heading="Delete User"
        action="Are you Sure? You want to Delete this User"
        ConfirmButton="Yes Delete"
        onClickFunction={handleDeleteUserButtonClickedApiHit}
        isOpen={confirmisOpen}
        onClose={closeConfirmModal}
        loader={true}
        loading={deleteloader}
      />
      <Flex justifyContent="space-between" mb="30px">
        <Heading as="h4" size="lg" fontWeight="medium">
          Users
        </Heading>
        <Flex gap="10px">
          <Button
            colorScheme="green"
            leftIcon={<AddIcon />}
            onClick={openAdduserModal}
          >
            Add Users
          </Button>
        </Flex>
      </Flex>
      <div
        style={{
          borderRadius: "7px",
          boxShadow:
            "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
          backgroundColor: "white",
        }}
        height="30px"
        width="30px"
      >
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>
                  <Text>S.No</Text>
                </Th>
                <Th>
                  <Text fontSize="sm">Name</Text>
                </Th>
                <Th>
                  <Text fontSize="sm">Email</Text>
                </Th>
                <Th>
                  <Text fontSize="sm">Role</Text>
                </Th>
                <Th>
                  <Text fontSize="sm" paddingLeft="20px">
                    Action
                  </Text>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {users?.map((item, i) => (
                <Tr key={i}>
                  <Td>{i + 1}</Td>
                  <Td>{item.name}</Td>
                  <Td>{item.email}</Td>

                  <Td>
                    {item.role.map((id, i) => (
                      <Box key={i}>
                        <Text>
                          {roles.find((role) => id === role._id)?.name}
                        </Text>
                      </Box>
                    ))}
                  </Td>
                  <Td>
                    {process.env.REACT_APP_SUPER_ADMIN_USER_ID !== item._id && (
                      <Flex gap="30px">
                        <IconButton
                          color="blue.500"
                          icon={<CiEdit fontSize="30px" onClick={() => {
                            openEdituserModal(item)
                          }} />}
                          variant="outline"
                        />
                        <IconButton
                          color="red.500"
                          onClick={() => openConfirmModal(item._id)}
                          icon={<AiOutlineDelete fontSize="30px" />}
                          variant="outline"
                        />
                      </Flex>
                    )}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default PartnerUser;
