import React, { useState } from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    FormControl,
    FormLabel,
    Input,
    useToast,
    InputGroup,
    InputRightElement,
    Spinner,
} from '@chakra-ui/react'
import { setToast } from '../../../Fucntions/Toastfunction'
import axios from 'axios'
import ConfirmationModal from '../../../Fucntions/Modals/ConfirmationModal'

const PasswordResetModal = ({ isOpen, onClose, partner_id }) => {
    const toast = useToast()
    const [password, setPassword] = useState("")
    const [confirmpass, setConfirmPass] = useState("")

    const [show, setShow] = React.useState(false)
    const handleClick = () => setShow(!show)

    const [cshow, setcShow] = React.useState(false)
    const handlecClick = () => setcShow(!cshow)

    const [confirmstatus,setConfirmstatus]=useState(false)
    const closeConfirmationModal=()=>{
        setConfirmstatus(false)
    }
    const openConconfirmStatus=()=>{
        if (!password || !confirmpass) {
            setToast(toast, "Please fill the password first", "", "info")
            setResetLoader(false)
            return
        }
        if (password !== confirmpass) {
            setToast(toast, "Password should be same", "", "error")
            setResetLoader(false)
            return
        }
        setConfirmstatus(true)
    }

    const [resetLoader, setResetLoader] = useState(false)
    function handlesavePassword() {
        if (resetLoader) {
            return
        }
        setResetLoader(true)
      
        const headers = {
            Authorization: `Bearer ${JSON.parse(
                sessionStorage.getItem("admintoken")
            )}`,
        };
        axios({
            method: "Put",
            url: `${process.env.REACT_APP_API_URL}/partner/resetpassword`,
            headers,
            data: {
                partner_id,
                password
            },
        })
            .then((r) => {
                setResetLoader(false)
                onClose()
                closeConfirmationModal()
                // console.log(r.data, "data")
                setToast(toast, `Password Reset Successfull`, "", "success")
            })
            .catch((err) => {
                setResetLoader(false)
                closeConfirmationModal()
                // console.log(err, "err")
                setToast(toast, `${err.response.data.err}`, "", "error")
            })
    }

    return (
        <div>
            <ConfirmationModal
            heading="Change Password"
            action={"Are you Sure? this will change the vendor's password"}
            onClickFunction={handlesavePassword}
            ConfirmButton={"Save"}
            onClose={closeConfirmationModal}
            isOpen={confirmstatus}
            color={"blue"}
            loading={true}
            loader={resetLoader}
            />
            <Modal closeOnOverlayClick={false}
                isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Reset Password</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl>
                            <FormLabel>Password</FormLabel>
                            <InputGroup size='md'>
                                <Input
                                    pr='4.5rem'
                                    type={show ? 'text' : 'password'}
                                    placeholder='Enter password'
                                    onChange={(e) => setPassword(e.target.value.trim())}
                                />
                                <InputRightElement width='4.5rem'>
                                    <Button h='1.75rem' size='sm' onClick={handleClick}>
                                        {show ? 'Hide' : 'Show'}
                                    </Button>
                                </InputRightElement>
                            </InputGroup>

                        </FormControl>
                        <br />
                        <FormControl>
                            <FormLabel>Confirm Password</FormLabel>

                            <InputGroup size='md'>
                                <Input
                                    pr='4.5rem'
                                    type={cshow ? 'text' : 'password'}
                                    placeholder='Confirm Password' onChange={(e) => setConfirmPass(e.target.value.trim())}

                                />
                                <InputRightElement width='4.5rem'>
                                    <Button h='1.75rem' size='sm' onClick={handlecClick}>
                                        {cshow ? 'Hide' : 'Show'}
                                    </Button>
                                </InputRightElement>
                            </InputGroup>
                        </FormControl>
                        <br />
                        <Button width="100%" colorScheme='blue'
                            onClick={openConconfirmStatus}>{ "Save"}</Button>
                    </ModalBody>
                    <ModalFooter>
                        {/* <Button colorScheme='blue' mr={3} onClick={onClose}>
              Done
            </Button> */}
                        <Button colorScheme='red' onClick={onClose}>close</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    )
}

export default PasswordResetModal