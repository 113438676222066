import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link, Navigate, useNavigate } from "react-router-dom";
import {
    getSingleData,
} from "../../../redux/Admin/AppReducer/Products/action";
import {
  Box,
  Flex,
  Heading,
  Image,
  Text,
  Button,
  useToast,

} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";



const ProductDetails = () => {
  const [load,setLoad]=useState(false)
  const navigate=useNavigate()
  const { id } = useParams();
  const loadingprod=useSelector((state)=>state.ProductReducer.isloading)
  const product = useSelector((state) => state.ProductReducer.singleProd);
  const imageValue = useRef();
  const dispatch = useDispatch();
  const toast=useToast()
//  console.log(product,"prod")
  useEffect(() => {
    dispatch(
      getSingleData(
        `${process.env.REACT_APP_API_URL}/product/${id}`,toast,navigate
      )
    );
    setTimeout(()=>{
      setLoad(true)
    },300)
  }, []);
//console.log(product,"***************")

  return (
    <>
   { loadingprod?
   <Text color="gray.800" fontSize="40px" 
   fontWeight="semibold" mt="80px" pb="80px">Loading ...</Text>  :
    <Box>
      <Flex mb="20px" width="fit-content" _hover={{cursor:"pointer"}}>
        <ArrowBackIcon fontSize="30px" 
         onClick={()=>navigate(-1)} />
      </Flex>
   {product[0]===null?<Text fontSize="25px"
   fontWeight="semibold" color='red.300'>No Product Available With This id</Text> :<Box>
      {product.map((item, i) => (
          <Box key={item.id}>
            <Flex
              gap="2rem"
              flexDirection={{ base: "column", md: "row", lg: "row" }}
            >
              <Box width={{ base: "100%", md: "30%", lg: "30%" }} gap="10px">
                <Image
                  src={imageValue.current ? imageValue.current : item.images[0]}
                  margin="auto"
                  alt={item.title}
                  width={{ base: "60%", md: "100%", lg: "100%" }}
                  height={{ base: "150px", md: "200px", lg: "250px" }}
                />
              </Box>
              <Box
                textAlign="left"
                width={{ base: "100%", md: "80%", lg: "70%" }}
              >
                <Heading as="h3" size="lg">
                  {item.title}
                </Heading>
                <Text fontSize="3xl">Price : {item.variants[0].price} </Text>
                <Text fontSize="2xl">{item.description}</Text>
              </Box>
            </Flex>
            {/* *******************************   VARIANTS BOX  ***************************** */}
            <Box textAlign="left" mt="20px">
              <Flex justifyContent="space-between">
                <Heading as="h3" size="xl" fontWeight="semibold" mb="20px">
                  Variants
                </Heading>
              </Flex>
              {product[0].variants.map((variant, index) => (
                <Flex
                  key={variant.id}
                  p={4}
                  boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
                  backgroundColor="white"
                  borderRadius="7px"
                  mb="20px"
                  justifyContent={'space-between'}
                  alignItems={'center'}
                >
                  <Heading as="h3" size="md" fontWeight="semibold" mb="5px">
                    {variant.title}
                  </Heading>

                  <Link to={`/admin/product/${product[0].id}/variant/${variant.id}`}>

                    <Button colorScheme="blue">Edit</Button>
                  </Link>
                </Flex>
              ))}
            </Box>
          </Box>
        ))}
        </Box>}
    </Box> 
        }
    </>
  
  );
};

export default ProductDetails;
