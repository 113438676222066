// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.datepicker::-webkit-datetime-edit {
    display:none !important;    visibility: hidden;
  }
  .datepicker::-webkit-calendar-picker-indicator {
      background: transparent;
      bottom: 0;
      color: transparent;
      cursor: pointer;
      height: auto;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: auto;
  }
 .datepicker::-webkit-calendar-picker-indicator{display:block !important;}`, "",{"version":3,"sources":["webpack://./src/Admin/Pages/orders/Date.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB,KAAK,kBAAkB;EAChD;EACA;MACI,uBAAuB;MACvB,SAAS;MACT,kBAAkB;MAClB,eAAe;MACf,YAAY;MACZ,OAAO;MACP,kBAAkB;MAClB,QAAQ;MACR,MAAM;MACN,WAAW;EACf;CACD,+CAA+C,wBAAwB,CAAC","sourcesContent":[".datepicker::-webkit-datetime-edit {\n    display:none !important;    visibility: hidden;\n  }\n  .datepicker::-webkit-calendar-picker-indicator {\n      background: transparent;\n      bottom: 0;\n      color: transparent;\n      cursor: pointer;\n      height: auto;\n      left: 0;\n      position: absolute;\n      right: 0;\n      top: 0;\n      width: auto;\n  }\n .datepicker::-webkit-calendar-picker-indicator{display:block !important;}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
